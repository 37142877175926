<template>
  <div class="search-container d-flex h-full flex-column">
    <form @submit="search()">
    <v-main class="bg-ligth-blue flex-grow-0 pb-6">
      <v-container>
        <div class="p-absolute alert-container right-5 w-full">
          <div class="alert-item alert--success" v-if="saveSuccessAlert">
            <img src="check-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Cambios Guardados</h6>
              <p>Las ediciones han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="alert-item alert--error" v-if="saveErrorAlert">
            <img src="error-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Error al guardar cambios.</h6>
              <p>Las ediciones no han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveErrorAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between mt-6 mb-3">
              <h2 class="text-blue-sky">Búsqueda de expedientes</h2>
            </div>
            <section class="search-box">
              <v-row>
                <v-col cols="12">
                  <label class="label-input mb-2 d-inline-block">Buscar</label>
                  <v-text-field clearable v-model="searchSelected.searchProjects" class="solo-border p-relative" placeholder="Buscar" flat single-line solo prepend-inner-icon="mdi-magnify"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                        <v-checkbox v-model="searchSelected.puestaDespacho"
                              class="mt-2"
                              label="Puesta a despacho"
                              color="#1867C0"
                              hide-details></v-checkbox>
                        <v-checkbox v-model="searchSelected.convocado"
                              class="mt-2"
                              label="Convocado"
                              color="#1867C0"
                              hide-details></v-checkbox>
                        <v-checkbox v-model="searchSelected.activo"
                              class="mt-2"
                              label="Activo"
                              color="#1867C0"
                              hide-details></v-checkbox>
                        <v-checkbox v-model="searchSelected.inactivo"
                              class="mt-2"
                              label="Inactivo"
                              color="#1867C0"
                              hide-details></v-checkbox>
                </v-col>
              </v-row>
            </section>
          </v-col>
          <v-col cols="12">
            <div class="d-flex mt-4">
              <v-btn class="label-input" color="#3C77AF" @click="search()">
                <span class="white--text">Buscar</span>
              </v-btn>
              <v-btn text class="label-input pr-0" @click="reset()">
                    <v-icon left color="#1867C0">
                      mdi-delete-outline
                    </v-icon>
                    <span class="text-blue-sky">
                      Limpiar
                    </span>
                  </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    </form>
    <v-main class="white py-4">
      <v-container>
        <v-row v-if="showResult && resultSearch.length > 0">
          <v-col>
            <div>
              <h3 class="text-body-1 font-weight-bold mb-4">{{ resultSearch.length }} Expedientes encontrados</h3>
              <div class="d-flex justify-space-between">
                <v-text-field
                  v-model="searchTable"
                  append-icon="mdi-magnify"
                  label="Filtar Resultados"
                  class="px-4"
                  clearable
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn class="ml-5 mt-3 label-input" color="#3C77AF" @click="save()">
                  <span class="white--text">Guardar Cambios</span>
                </v-btn>
              </div>
              <v-data-table
                  :header-props="headerTableProps"
                  locale="es-ES"
                  :headers="headers"
                  :items="resultSearch"
                  :search="searchTable"
                  :footer-props="{
                      'items-per-page-text':'Expedientes por pagina',
                      'items-per-page-options': [
                          -1,
                          50,
                          100,
                        ]
                  }"
                >
                <template v-slot:header.convocado="{ header }">
                  <span class="text-no-wrap" v-bind:class="{'cursor-disabled': initData.isOrdinaria }">
                    <v-checkbox v-model="convocadoAll" class="mt-0"
                                :disabled="initData.isOrdinaria"
                                @click="checkConvocadoAll()"
                                :label="header.text"
                                color="#1867C0"
                                hide-details></v-checkbox>
                  </span>
                </template>
                <template v-slot:header.puestaDespacho="{ header }">
                  <span class="text-no-wrap">
                    <v-checkbox v-model="puestaDespachoAll" class="mt-0"
                                @click="checkPuestaDespachoAll()"
                                :label="header.text"
                                color="#1867C0"
                                hide-details></v-checkbox>
                  </span>
                </template>
                <template v-slot:item="row">
                    <tr class="cursor-pointer">
                      <td @click="openProject(row.item.id)"
                        class="text-green-marine cursor-pointer hover-underline" style="
                          width: 120px;
                      ">
                      {{row.item.expediente}}
                      </td>
                      <td>{{row.item.nombre}}</td>
                      <td>{{row.item.monitoreo}}</td>
                      <td style="width: 120px;">
                        <span class="text-no-wrap" v-bind:class="{'cursor-disabled': initData.isOrdinaria }">
                          <v-checkbox v-model="row.item.convocado" class="mt-0"
                          :disabled="initData.isOrdinaria"
                              color="#1867C0"
                              hide-details></v-checkbox>
                        </span>
                      </td>
                      <td style="width: 120px;">
                        <v-checkbox v-model="row.item.puestaDespacho" class="mt-0"
                                color="#1867C0"
                                hide-details></v-checkbox>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="showResult && resultSearch.length === 0">
          <v-col class="justify-center d-flex flex-column">
              <img class="margin-center mt-12" src="no-result.png" alt="No hay resultados" width="440">
              <p class="text-center text-h6 font-weight-regular mt-6">No hay resultados encontrados.</p>
          </v-col>
        </v-row>
        <v-row v-if="!showResult">
          <v-col class="justify-center d-flex flex-column">
            <img class="margin-center mt-12" src="init-result.png" alt="No hay busquedas" width="440">
            <p class="text-center text-h6 font-weight-regular mt-6">Use los filtros para buscar expedientes.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>

export default {
  name: 'ProjectsEditorView',
  components: {
  },
  created() {
  },
  computed: {
  },
  data: () => ({
    initData: {},
    saveSuccessAlert: false,
      saveErrorAlert: false,
    convocadoAll: false,
    puestaDespachoAll: false,
    searchTable: '',
    headerTableProps: {
        sortByText: "Filtrar por"
    },
    headers: [
        {
          text: 'Expediente',
          align: 'start',
          sortable: true,
          value: 'expediente',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Monitoreo',
          align: 'start',
          sortable: false,
          value: 'monitoreo',
        },
        {
          text: 'Convocado',
          align: 'start',
          sortable: false,
          value: 'convocado',
        },
        {
          text: 'Puesta a Despacho',
          align: 'start',
          sortable: false,
          value: 'puestaDespacho',
        }
      ],
    showResult: false,
    defaultSearch: {
      searchProjects: '',
      convocado: false,
      puestaDespacho: false,
    },
    searchSelected: {
      searchProjects: '',
      convocado: false,
      puestaDespacho: false,
    },
    resultSearch: [],
  }),
  watch: {
    saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
  },
  mounted() {
    this.searchSelected = JSON.parse(localStorage.getItem('searchEditSelected')) || this.defaultSearch;
    this.loadInitData();
  },
  methods: {
    openProject(id) {
      this.$router.push({ name: 'ProjectView', params: { id } })
    },
    checkPuestaDespachoAll() {
      this.resultSearch = this.resultSearch.map((item) => {
        item.puestaDespacho = this.puestaDespachoAll;
        return item;
      });
    },
    checkConvocadoAll() {
      this.resultSearch = this.resultSearch.map((item) => {
        item.convocado = this.convocadoAll;
        return item;
      });
    },
    search() {
      localStorage.setItem('searchEditSelected', JSON.stringify(this.searchSelected));
      fetch('/api/ProjectsEditor/Search', {
        method: 'POST',
        body: JSON.stringify(this.searchSelected),
        headers: {
          'Authorization' : `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.showResult = true;
          this.resultSearch = data.sort((a, b) => b.expediente - a.expediente);
        })
        .catch(error => console.error(error));
    },
    loadInitData() {
      fetch('/api/Project/InitData', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.initData = data;
          if (JSON.stringify(this.searchSelected) !== JSON.stringify(this.defaultSearch)) {
            this.search();
          }
        })
        .catch(error => console.error(error));
    },
    save() {
      fetch('/api/ProjectsEditor', {
        method: 'POST',
        body: JSON.stringify(this.resultSearch),
        headers: {
          'Authorization' : `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
            if(response.status === 200) {
              this.saveSuccessAlert = true;
            } else {
              this.saveErrorAlert = true;
            }
            return response;
        })
        .then(response => response.json())
        .then(data => {
          this.showResult = true;
          this.resultSearch = [...data];
        })
        .catch(error => console.error(error));
    },
    reset() {
      localStorage.setItem('searchEditSelected', null);
      this.searchSelected =  Object.assign({}, this.defaultSearch);
      this.resultSearch = [];
      this.showResult = false;
    },
  },
};
</script>
