<template>
  <div class="d-flex h-full flex-column">
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">
        <img src="logo-con-texto-2021.svg" class="cursor-pointer" @click="goToSearch()" style="width: 140px;" alt="Logo ABC"/>
        <v-spacer></v-spacer>
        <v-btn v-if="$route.name !== 'SearchView'" outlined text class="label-input mr-10" @click="goBack()">
          <span class="font-weight-bold">Cerrar</span>
        </v-btn>
        <v-btn color="#3C77AF" outlined v-if="$route.name == 'SearchView'" text class="label-input mr-10" @click="downloadHeatMap()">
          <span class="font-weight-bold">Gestión legislativa</span>
        </v-btn>
        <div class="d-flex flex-row justify-end align-center">
          <v-avatar class="mr-3"
                    color="primary"
                    size="32">
                    <span class="white--text">{{shortName}}</span>
                  </v-avatar>
          <span class="d-flex">
            <span class="d-none d-sm-flex align-center">
              {{ userFullName }}
            </span>
            <v-menu 
                    bottom
                    offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs"
                       v-on="on"
                       color="grey">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in userActions":key="index">
                  <template v-if="item.text === 'Perfil'">
                    <v-list-item-title class="cursor-pointer" @click="goToProfile()">{{ item.text }}</v-list-item-title>
                   </template>
                   <template v-if="item.text === 'Usuarios'">
                    <v-list-item-title class="cursor-pointer" @click="goToUsers()">{{ item.text }}</v-list-item-title>
                   </template>
                   <template v-if="item.text === 'Cerrar Sesión'">
                    <v-list-item-title class="cursor-pointer" @click="logout()">{{ item.text }}</v-list-item-title>
                   </template>
                   <template v-if="item.text === 'Configuración'">
                    <v-list-item-title class="cursor-pointer" @click="config()">{{ item.text }}</v-list-item-title>
                   </template>
                   <template v-if="item.text === 'Editor masivo'">
                    <v-list-item-title class="cursor-pointer" @click="editorMasivo()">{{ item.text }}</v-list-item-title>
                   </template>
                   
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </div>
      </v-container>
    </v-app-bar>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'DashboardView',
  components: {
  },
  computed: {
    
  },
  created() {
    this.userFullName = localStorage.getItem('userFullName');
    const words = this.userFullName.split(' ');
    this.shortName = `${words[0][0]}${words[1] ? words[1][0] : ''}`.toUpperCase();
    if (localStorage.getItem('userRole') && localStorage.getItem('userRole') === 'cfb41f8e-9aa3-4d18-8fe6-7198fa2a4cce') {
      this.userActions = [
        {
          text: 'Perfil'
        },
        {
          text: 'Usuarios'
        },
        {
          text: 'Configuración'
        },
        {
          text: 'Editor masivo'
        },
        {
          text: 'Cerrar Sesión',
        }
      ]
    }
  },
  data: () => ({
    userActions: [
      {
        text: 'Perfil'
      },
      {
        text: 'Cerrar Sesión',
      }
    ],
    userFullName: '',
    shortName: ''
  }),
  methods: {
    logout() {
      localStorage.clear('token');
      localStorage.setItem('searchSelected', null);
      this.$router.push('/login');
    },
    config() {
      this.$router.push('/setting');
    },
    goToUsers() {
      this.$router.push('/users');
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    editorMasivo() {
      this.$router.push('/projectsEditor');
    },
    goToSearch(){
      localStorage.setItem('searchSelected', null);
      this.$router.push('/');
    },
    goBack(){
      if(this.$route.name === 'ReadOnlyInputProjectView' || this.$route.name === 'ProjectView') {
        this.goToSearch();
      } else {
        this.$router.back();
      }
    },
    downloadHeatMap() {
      
      fetch('/api/Project/HeatMap', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.blob())
        .then(response => {
          this.isDownloading = false;
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadUrl = URL.createObjectURL(blob);
          const downaloadLink = document.createElement("a");
          downaloadLink.href = downloadUrl;
          downaloadLink.download = "ABC-GestionLegislativa.xlsx";
          document.body.appendChild(downaloadLink);
          downaloadLink.click();
          document.body.removeChild(downaloadLink);
        })
        .catch(error => {
          this.isDownloading = false;
          this.searching = false;
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/styles/main.scss';
</style>
