<style scoped>
.text-field-date-doc{
   padding: 0;
}
.v-application .v-btn.v-btn--disabled .white--text {
  color: gray !important;
}
</style>
<template>
    <div>
      <v-main class="white mt-1">
        <v-progress-linear
          :active="loadingProject"
          :indeterminate="loadingProject"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>
        <v-progress-linear
          :active="savingProject"
          :indeterminate="savingProject"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>
        <v-scale-transition>
          <v-form class="form-project" lazy-validation v-if="!loadingProject" ref="formView">
            <div class="form-container">
              <v-container>
              <v-row class="pb-5">
                <v-col>
                  <div class="header-actions d-flex justify-space-between mb-3">
                    <h2 class="text-blue-sky">Expediente</h2>
                  </div>
                  <div class="action-wrapper d-flex flex-column flex-sm-row">
                    <div>
                      <v-btn text class="label-input ml-4 ml-sm-2 pl-0 text-blue-sky" :disabled="!(currentProject.document && currentProject.document.url)"
                          target="_blank" :href="(currentProject.document && currentProject.document.url) ?
                      (isOfficeDoc(currentProject.document.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${currentProject.document.url}` : currentProject.document.url) : ''">
                        <v-icon left>
                          mdi-file-document-multiple-outline
                        </v-icon>
                        <span class="text-decoration-underline">Ver Documento Vigente</span>
                      </v-btn>
                      <a v-if="currentProject.document && currentProject.document.url" v-bind:href="currentProject.document.url" download>
                        <v-icon color="#1867C0" >
                          mdi-download
                        </v-icon>
                      </a>
                    </div>
                    <div>
                      <v-dialog v-model="dbitacora"
                                width="1200">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="label-input ml-2 text-blue-sky"
                                v-bind="attrs"
                                v-on="on">
                            <v-icon left>
                              mdi-comment-outline
                            </v-icon>
                            <span class="text-decoration-underline">Bitácora</span>
                          </v-btn>
                        </template>
                        <v-card class="modal-max-h">
                          <v-card-title class="text-blue-sky lighten-2">
                            <h6 class="text-h6 mt-8 mb-4">Bitácora</h6>
                            <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                              <v-icon class="" @click="dbitacora = false">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <div class="list-container">
                              <template v-if="currentProject.bitacoras.length">
                                <v-card class="mt-3 pa-6" v-for="(item, i) in currentProject.bitacoras" :key="i">
                                  <div class="d-flex justify-space-between mb-3">
                                    <div class="text-body-2">
                                      <p class="mb-0 text-blue-sky font-weight-bold">{{ item.userName }}</p>
                                      <span class="text-gray-500"> {{ item.date }}</span>
                                    </div>
                                  </div>
                                  <v-tiptap v-model="item.comment" view />
                                </v-card>
                              </template>
                              <template v-else>
                                <p>
                                  No hay resultados encontrados.
                                </p>
                              </template>
                            </div>
                          </v-card-text>
                          <v-card-actions class="d-flex justify-end pb-5 px-6">
                            <v-btn color="#1867C0" outlined width="160" class="label-input"
                                  @click="dbitacora = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </div>
                  <section class="info-wrapper mt-8">
                    <h3 class="font-weight-bold text-h6 text-blue-sky">
                      Información General
                    </h3>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="numeroExpediente" class="label-read-only d-inline-block text-blue-sky">Número:</label>
                        <p class="my-0 value-read-only" id="numeroExpediente"> {{currentProject.expediente}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="Estado" class="label-read-only d-inline-block text-blue-sky">Estado:</label>
                        <p class="my-0 value-read-only" id="Estado"> {{currentProject.estado.value}}</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" class="d-flex">
                        <label for="Nombre" class="label-read-only d-inline-block text-blue-sky">Nombre</label>
                        <p class="my-0 value-read-only" id="Nombre"> {{currentProject.nombre}}</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2" v-if="!isExternalUser">
                      <v-col class="d-flex">
                        <label for="Estado" class="label-read-only d-inline-block text-blue-sky">Conocido Como:</label>
                        <p class="my-0 value-read-only" id="Estado"> {{currentProject.alias}}</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" class="d-flex">
                        <label for="Resumen" class="label-read-only d-inline-block text-blue-sky">Resumen</label>
                        <p class="my-0 value-read-only" id="Resumen">
                          <v-tiptap v-model="currentProject.resumen" view />
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" class="d-flex">
                        <label for="Proponente" class="label-read-only d-inline-block text-blue-sky">Proponente</label>
                        <p class="my-0 value-read-only" id="Proponente"> {{displayList(currentProject.proponente)}}</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="Partido" class="label-read-only d-inline-block text-blue-sky">Partido</label>
                        <p class="my-0 value-read-only" id="Partido"> {{currentProject.partido.value}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex" v-if="!isExternalUser">
                        <label for="Tema" class="label-read-only d-inline-block text-blue-sky">Tema</label>
                        <p class="my-0" id="Tema"> {{currentProject.tema}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex" v-if="!isExternalUser">
                        <label for="Cobertura" class="label-read-only d-inline-block text-blue-sky">Cobertura</label>
                        <p class="my-0 value-read-only" id="Cobertura"> {{currentProject.cobertura.value}}</p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2" v-if="!isExternalUser">
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="Categorias" class="label-read-only d-inline-block text-blue-sky">Categorías</label>
                        <p class="my-0 value-read-only" id="Categorias"> {{displayList(currentProject.categorias)}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="Afectacion" class="label-read-only d-inline-block text-blue-sky">Afectación</label>
                        <p class="my-0" id="Afectacion"> {{currentProject.afectacion.value}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="Afectacion" class="label-read-only d-inline-block text-blue-sky">Afecta A</label>
                        <p class="my-0 value-read-only" id="Afectacion"> {{currentProject.afectaA.value}}</p>
                      </v-col>
                    </v-row>
                  </section>
                  <section class="info-wrapper mt-8">
                    <h3 class="font-weight-bold text-h6 text-blue-sky">
                      {{currentProject.type === 'tramiteOrdinario' ? 'Trámite Ordinario':'Trámite Reforma Constitucional'}}
                    </h3>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="FechaPresentacion" class="label-read-only d-inline-block text-blue-sky">Fecha Presentación</label>
                        <p class="my-0 value-read-only" id="FechaPresentacion"> {{currentProject.fechaPresentacionValue}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="encimiento Cuatrienal" class="label-read-only d-inline-block text-blue-sky">Vencimiento Cuatrienal</label>
                        <p class="my-0 value-read-only" id="Estado"> {{currentProject.vencimientoCuatrienalValue}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex" v-if="!isExternalUser">
                          <label for="FechaPresentacion" class="label-read-only d-inline-block text-blue-sky">Monitoreo</label>
                          <p class="my-0 value-read-only" id="FechaPresentacion"> {{currentProject.monitoreo.value}}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" sm="4" class="d-flex">
                        <label for="fase" class="label-read-only d-inline-block text-blue-sky">Fase</label>
                        <p class="my-0 value-read-only" id="fase"> {{currentProject.fase.value}}</p>
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex">
                        <template v-if="currentProject.fase && currentProject.fase.value == 'Comisión'">
                          <label class="label-read-only d-inline-block text-blue-sky">Subfase</label>
                          <p class="my-0 value-read-only"> {{currentProject.subfase.value}}</p>
                        </template>
                        <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                          <label class="label-read-only d-inline-block text-blue-sky">Subfase</label>
                          <p class="my-0 value-read-only"> {{currentProject.plenarioSubfase.value}}</p>
                        </template>
                        <template v-if="currentProject.fase.value == 'Comisiones Plenas'">
                          <label class="label-read-only d-inline-block text-blue-sky">Plenas</label>
                          <p class="my-0 value-read-only"> {{currentProject.plena.value}}</p>
                        </template>
                      </v-col>
                      <template v-if="(currentProject.plenarioSubfase && currentProject.fase) && currentProject.fase.value == 'Plenario' && currentProject.plenarioSubfase.value == 'Primer Debate'">
                        <v-col cols="12" sm="4" class="d-flex">
                          <label for="FechaPresentacion" class="label-read-only d-inline-block text-blue-sky">Subfase 2</label>
                          <p class="my-0 value-read-only" id="FechaPresentacion"> {{currentProject.subSubFase.value}}</p>
                        </v-col>
                    </template>
                    </v-row>
                    <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                      <v-row class="mt-3 border-row-y py-2">
                        <v-col cols="12" class="d-flex">
                          <label for="FechaPresentacion" class="label-read-only d-inline-block text-blue-sky">Comisión</label>
                          <p class="my-0 value-read-only" id="FechaPresentacion"> {{currentProject.comision.value}}</p>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-if="currentProject.fase.value == 'Comisión'">
                      <v-row class="mt-3 border-row-y py-2">
                        <v-col cols="12" sm="4" class="d-flex">
                          <label class="label-read-only d-inline-block text-blue-sky">Comisión</label>
                          <p class="my-0 value-read-only"> {{currentProject.comision.value}}</p>
                        </v-col>
                        <v-col cols="12" sm="4" class="d-flex">
                          <label for="encimiento Cuatrienal" class="label-read-only d-inline-block text-blue-sky">Orden del día</label>
                          <p class="my-0 value-read-only"> {{currentProject.ordenSubcomision}}</p>
                        </v-col>
                        <v-col cols="12" sm="4" class="d-flex">
                          <label for="encimiento Cuatrienal" class="label-read-only d-inline-block text-blue-sky">Plazo Para Dictaminar</label>
                          <p class="my-0 value-read-only"> {{currentProject.plazoDictaminarSubcomision}}</p>
                        </v-col>
                      </v-row>
                      <template v-if="currentProject.subfase.value == 'Subcomisión'">
                        <v-row class="mt-3 border-row-y py-2">
                          <v-col cols="12" sm="6" class="d-flex">
                            <label for="encimiento Cuatrienal" class="label-read-only d-inline-block text-blue-sky">SubComision</label>
                            <p class="my-0 value-read-only"> {{proponenteList(currentProject.proponenteSubcomision)}}</p>
                          </v-col>
                        <v-col cols="12" sm="6" class="d-flex">
                          <label for="encimiento Cuatrienal" class="label-read-only d-inline-block text-blue-sky">Plazo de Subcomisión</label>
                          <p class="my-0 value-read-only"> {{currentProject.plazoSubcomision}}</p>
                        </v-col>
                      </v-row>
                      </template>
                    </template>
                    <v-row class="mt-3 border-row-y py-2">
                      <v-col cols="12" class="d-flex">
                        <div class="d-flex flex-wrap justify-space-around w-full">
                          <v-checkbox :disabled="true"
                            class="ma-0 pa-0 value-read-only"
                            v-model="currentProject.puestaDespacho"
                            label="Puesta a despacho"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox :disabled="true"
                          class="ma-0 pa-0 value-read-only"
                            v-model="currentProject.dispensaDeTramite"
                            label="Dispensa de Trámite"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox :disabled="true"
                          class="ma-0 pa-0 value-read-only"
                            v-model="currentProject.tieneMociones"
                            label="Tiene Mociones"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox :disabled="true"
                          class="ma-0 pa-0 value-read-only"
                            v-model="currentProject.viaRapida"
                            label="Vía Rápida"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox :disabled="true"
                          class="ma-0 pa-0 value-read-only"
                            v-model="currentProject.convocado"
                            label="Convocado"
                            color="#1867C0"
                            hide-details
                            ></v-checkbox>
                            <v-checkbox
                            :disabled="true"
                              class="ma-0 pa-0 value-read-only"
                                v-model="currentProject.consultado"
                                color="#1867C0"
                                hide-details
                              >
                              <template v-slot:label>
                                <span>Consultado ABC {{currentProject.consultado && currentProject.consultados.length > 0 ? `(${currentProject.consultados.length})` : '' }}</span>
                              </template>
                            </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </section>
                  <section class="info-wrapper mt-8">
                    <h3 class="font-weight-bold text-h6 text-blue-sky">
                      Documentos
                    </h3>
                    <v-text-field
                            v-model="searchDocTable"
                            append-icon="mdi-magnify"
                            label="Filtar"
                            class="px-4"
                            clearable
                            single-line
                            hide-details
                          ></v-text-field>
                          <v-data-table
                              :header-props="headerDocTableProps"
                              locale="es-ES"
                              :headers="headersDoc"
                              :items="currentProject.documentos"
                              :search="searchDocTable"
                              :footer-props="{
                                  'items-per-page-text':'Documentos por pagina',
                                  'items-per-page-options': [
                                    20,
                                    40,
                                    60,
                                    -1
                                  ]
                              }"
                            >
                            <template v-slot:item="row">
                                <tr @click="openProject(row.item.id)" class="cursor-pointer">
                                  <td >
                                    <v-icon v-text="getDocIcon(row.item.text)"></v-icon>
                                  </td>
                                  <td class="text-green-marine cursor-pointer hover-underline">
                                    <a target="_blank" :href="isOfficeDoc(row.item.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${row.item.url}` : row.item.url">
                                      <span class="primary-text">
                                        {{ row.item.text }}
                                      </span>
                                    </a>
                                  </td>
                                  <td class="text-green-marine">
                                      <span class="primary-text">
                                        {{ row.item.fecha }}
                                      </span>
                                  </td>
                                  <td class="d-flex justify-end">
                                    <a class="ml-auto mt-2" v-bind:href="row.item.url" download>
                                    <v-icon color="grey" >
                                      mdi-download
                                    </v-icon>
                                  </a>
                                  </td>
                                </tr>
                            </template>
                          </v-data-table>
                  </section>
                </v-col>
              </v-row>
            </v-container>
            </div>
            <div class="border-top">
              <v-container>
                <div class="d-flex justify-end">
                  <v-spacer></v-spacer>
                  <v-btn text class="label-input" @click="close()">
                    <span class="font-weight-bold">Cerrar</span>
                  </v-btn>
                </div>
              </v-container>
            </div>
          </v-form>
        </v-scale-transition>
      </v-main>
    </div>
</template>
<script>
  import { format } from 'date-fns'

  export default {
    name: 'ReadOnlyProjectView',
    components: {
    },
    data: () => ({
      searchDocTable: '',
      headerDocTableProps: {
        sortByText: "Filtrar por"
      },
      headersDoc: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'text',
        },
        {
          text: 'Fecha del Documento',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        {
          text: '',
          align: 'end',
          sortable: false
        }
      ],
      loadingProject: false,
      isFormValid: false,
      isSelecting: false,
      // Bitacora:
      bitacoraCurrentIndex: null,
      dateBitacoraMenu: false,
      isEditView: false,
      bitacoraEditor: {
        userName: "",
        text: "",
        date: null
      },
      currentDoc:{
        url: ''
      },
      currentProject: {
        id: "00000000-0000-0000-0000-000000000000",
        expediente: 0,
        alias: "",
        nombre: "",
        estado: null,
        textoVigente: {},
        archivado: false,
        proponente: null,
        partido: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        tema: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        cobertura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        categorias: [{
          key: "00000000-0000-0000-0000-000000000000",
          value: ""
        }],
        afectacion: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        fechaPresentacionMenu: false,
        fechaPresentacionValue: null,
        vencimientoCuatrienalMenu: false,
        vencimientoCuatrienalValue: null,
        monitoreo: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        fase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subSubFase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plenarioSubfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        legislatura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plena: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        comision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionTramite: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionSubComision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        ordenTramite: 0,
        puestaDespacho: false,
        convocado: false,
        consultado: false,
        viaRapida: false,
        viaRapidaResumen: '',
        dispensaDeTramite: false,
        tieneMociones: false,
        plazoDictaminarTramiteMenu: false,
        plazoDictaminarTramite: null,
        plazoDictaminarSubcomisionMenu: false,
        plazoDictaminarSubcomision: null,
        ordenSubcomision: 0,
        proponenteSubcomision: [

        ],
        plazoSubcomision: null,
        estados: [

        ],
        consultados: [

        ],
        documentos: [

        ],
        bitacora: [

        ],
        resumen: ""
      },
      initData: {},
      dresumen: false,
      dbitacora: false,
      dconsultado: false,
      dviaRapida: false,
      valid: false,
      bitacora: [{
        personName: "",
        text: "",
        date: null
      }],
      resumen: "",
    }),
    created() {
    },
    mounted() {
      this.loadProject();
      this.docDate = format(new Date(), 'yyyy-MM-dd');
    },
    computed: {
      isExternalUser(){
        if (localStorage.getItem('userRole') && localStorage.getItem('userRole') === '79f6fa11-a0d2-4663-8cdd-1edf545a335f') {
          return true;
        }
        return false
      }
    },
    methods: {
      displayList(list) {
        if(list && list.length){
          return list.map((obj) => obj.value).join(', \n')
        }
        return '';
      },
      getDocIcon(filename) {
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'pdf':
            return 'mdi-file-pdf-box';
            case 'doc':
            case 'docx':
            return 'mdi-file-word';
            case 'xls':
            case 'xlsx':
              return 'mdi-microsoft-excel';
            default:
              return 'mdi-file-document-outline';
          }
        }
      },
      isOfficeDoc(filename){
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
              return true;
            default:
              return false;
          }
        }
        return false;
      },
      loadProject() {
        var self = this;
        this.loadingProject = true;
        fetch('/api/Project/' + this.$route.params.id, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            self.currentProject = data;
            if (this.$route.params.type) {
              self.currentProject.type = this.$route.params.type;
            }
            this.loadingProject = false;
            // self.currentProject.documentos[0].isCurrentDocument = true;
          })
          .catch(error => {console.error(error)});
      },
      close() {
        this.$router.push({ name: 'SearchView' })
      }
    }
};
</script>
