<template>
  <div class="search-container d-flex h-full flex-column">
    <v-main class="bg-ligth-blue flex-grow-0 pb-6">
      <form @submit.prevent="search()">
      <v-container>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between mt-6 mb-3">
              <h2 class="text-blue-sky">Búsqueda de expedientes</h2>
              <v-menu
        v-if="isSuperAdmin"
        v-model="agregarProyectoMenu"
        absolute
        offset-y
        style="max-width: 600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="label-input ml-5" color="#3C77AF"
            v-bind="attrs"
            v-on="on">
              <span class="white--text">Agregar Proyecto</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="create('tramiteOrdinario')">
            <v-list-item-title>Trámite Ordinario</v-list-item-title>
          </v-list-item>
          <v-list-item @click="create('reformaConstitucional')">
            <v-list-item-title>Trámite Reforma Constitucional</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
            </div>
            <section class="search-box">
              <v-row>
                <v-col cols="12"
                       lg="5"
                       md="4"
                       sm="12">
                  <label class="label-input mb-2 d-inline-block">Buscar</label>
                  <v-text-field clearable v-model="searchSelected.searchText[0]" class="solo-border z-index-6 p-relative" placeholder="Buscar" flat single-line solo prepend-inner-icon="mdi-magnify" :disabled="expedienteHasValue"
                  append-outer-icon="mdi-plus-circle-outline" @click:append-outer="addMoreSearchText">
                  </v-text-field>
                  <template v-for="(result, index) in searchSelected.searchText">
                    <v-text-field
                    clearable
                      class="solo-border gutter-spacer" v-bind:class="[`spacer-${index}` , `z-index-${6 - index}`]"
                      v-bind:key="index"
                      v-if="index !== 0"
                      v-model="searchSelected.searchText[index]"
                      placeholder="Buscar"
                      flat single-line solo prepend-inner-icon="mdi-magnify"
                      :disabled="expedienteHasValue"
                      append-outer-icon="mdi-close-circle-outline"
                      @click:append-outer="removeMoreSearchText(index)">
                    </v-text-field>
                  </template>
                </v-col>
                <v-col cols="12"
                       md="2"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Número de Expediente</label>
                  <v-text-field clearable v-model="searchSelected.numeroExpediente" type="number" class="solo-border" placeholder="Número" flat single-line solo></v-text-field>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Fase</label>
                  <v-combobox class="solo-border" multiple :disabled="expedienteHasValue" clearable
                              placeholder="Fase"
                              flat
                              v-model="searchSelected.faces"
                              :items="initData.fases"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       lg="2"
                       md="3"
                       sm="12" class="pt-4 px-0 d-flex pr-4 align-self-center">
                  <v-btn  v-if="!isReadOnlyUser" :disabled="expedienteHasValue" text class="label-input px-0 text-blue-sky" @click="searchSelected.moreFiltersIsOpen = !searchSelected.moreFiltersIsOpen">
                    <v-icon
                      left
                      dark
                      color="#1867C0"
                    >{{ searchSelected.hasMoreFilters ? 'mdi-filter-check' : 'mdi-filter-outline' }}
                    </v-icon>
                    <span class="text-blue-sky">
                      Filtros
                    </span>
                  </v-btn>
                  <v-btn text class="label-input pr-0" @click="reset()">
                    <v-icon left color="#1867C0">
                      mdi-delete-outline
                    </v-icon>
                    <span class="text-blue-sky">
                      Limpiar
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </section>
          </v-col>
          <v-col cols="12" v-if="!isReadOnlyUser && searchSelected.moreFiltersIsOpen && !(expedienteHasValue)">
            <h3 class="text-body-1 font-weight-bold mb-4">Filtros Avanzados</h3>
            <v-row>
              <v-col cols="12"
                      md="3"
                      sm="6">
                <label class="label-input mb-2 d-inline-block">Proponente</label>
                <v-combobox class="solo-border" clearable
                            placeholder="Proponente"
                            flat
                            v-model="searchSelected.proponente"
                            :items="initData.proponentes"
                            item-value="key"
                            item-text="value"
                            solo></v-combobox>
              </v-col>
              <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Período</label>
                  <v-combobox class="solo-border" clearable
                              placeholder="Período"
                              flat
                              v-model="searchSelected.tipoFecha"
                              :items="initData.tipoFechas"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Desde</label>
                  <v-menu v-model="searchSelected.menudate1" min-width="auto" offset-y
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="solo-border" v-model="searchSelected.desde"
                                      placeholder="Desde" clearable
                                      flat single-line solo prepend-inner-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="searchSelected.desde"
                                    :max="searchSelected.hasta"
                                      @input="searchSelected.menudate1 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Hasta</label>
                  <v-menu v-model="searchSelected.menudate2" min-width="auto" offset-y
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="solo-border" v-model="searchSelected.hasta"
                                      placeholder="Hasta" clearable
                                      flat single-line solo prepend-inner-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="searchSelected.hasta"
                                      :min="searchSelected.desde"
                                      @input="searchSelected.menudate2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Partido</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Partido" clearable
                              flat
                              v-model="searchSelected.partidos"
                              :items="initData.partidos"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Estado</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Estado" clearable
                              flat
                              v-model="searchSelected.estados"
                              :items="initData.estadosSearch"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Categoría</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Categoría" clearable
                              flat
                              v-model="searchSelected.categorias"
                              :items="initData.categorias"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Cobertura</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Cobertura" clearable
                              flat
                              v-model="searchSelected.coberturas"
                              :items="initData.coberturas"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Afecta A</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Afecta A" clearable
                              flat
                              v-model="searchSelected.afectaA"
                              :items="initData.afectaA"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Comision</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Comision" clearable
                              flat
                              v-model="searchSelected.comisiones"
                              :items="initData.comisiones"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Afectación</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Afectación" clearable
                              flat
                              v-model="searchSelected.afectaciones"
                              :items="initData.afectaciones"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
                <v-col cols="12"
                       md="3"
                       sm="6">
                  <label class="label-input mb-2 d-inline-block">Monitoreo</label>
                  <v-combobox class="solo-border" multiple
                              placeholder="Monitoreo" clearable
                              flat
                              v-model="searchSelected.monitoreo"
                              :items="initData.monitoreos"
                              item-value="key"
                              item-text="value"
                              solo></v-combobox>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex align-center">
                <v-checkbox v-model="searchSelected.dispensaTramite"
                      label="Dispensa de Tramite"
                      color="#1867C0"
                      hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.puestaDespacho"
                            label="Puesta a despacho"
                            color="#1867C0"
                            hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.convocado"
                            label="Convocado"
                            color="#1867C0"
                            hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.consultado"
                            label="Consultado ABC"
                            color="#1867C0"
                            hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.archivado"
                            label="Archivado"
                            color="#1867C0"
                            hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.tieneMociones"
                      label="Tiene Mociones"
                      color="#1867C0"
                      hide-details></v-checkbox>
                <v-checkbox v-model="searchSelected.leyDeLaRepublica"
                label="Ley de la República"
                color="#1867C0"
                hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="d-flex mt-4">
              <v-btn type="submit" class="label-input" color="#3C77AF" :loading="searching">
                <span class="white--text">Buscar</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </form>
    </v-main>
    <v-main class="white py-4">
      <v-container>
        <v-row v-if="showResult && resultSearch.length > 0">
          <v-col>
            <div>
              <div class="d-flex justify-space-between">
                <h3 class="text-body-1 font-weight-bold mb-4">{{ resultSearch.length }} Expedientes encontrados</h3>
                <div class="d-flex" v-if="!isReadOnlyUser">
                  <v-btn color="#3C77AF" class="label-input" outlined @click="searchDownload()" :loading="isDownloading">
                    <span class="">Descargar Resultados</span>
                  </v-btn>
                </div>
              </div>
              <v-text-field
                  v-model="searchTable"
                  append-icon="mdi-magnify"
                  label="Filtar Resultados"
                  class="px-4"
                  clearable
                  single-line
                  hide-details
                ></v-text-field>
              <v-data-table
                  :header-props="headerTableProps"
                  locale="es-ES"
                  :headers="headers"
                  :items="resultSearch"
                  :search="searchTable"
                  :footer-props="{
                      'items-per-page-text':'Expedientes por pagina',
                      'items-per-page-options': [
                          50,
                          100,
                          -1
                        ]
                  }"
                >
                <template v-slot:item="row">
                    <tr @click="openProject(row.item.id)" class="cursor-pointer">
                      <td class="text-green-marine cursor-pointer hover-underline" style="
                          min-width: 120px;
                      ">
                        <router-link
                        :to="{ path: `project/${row.item.id}`}">
                        {{row.item.expediente}}
                      </router-link>
                      </td>
                      <td>{{row.item.nombre}}</td>
                    </tr>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="showResult && resultSearch.length === 0">
          <v-col class="justify-center d-flex flex-column">
              <img class="margin-center mt-12" src="no-result.png" alt="No hay resultados" width="440">
              <p class="text-center text-h6 font-weight-regular mt-6">No hay resultados encontrados.</p>
          </v-col>
        </v-row>
        <v-row v-if="!showResult">
          <v-col class="justify-center d-flex flex-column">
            <img class="margin-center mt-12" src="init-result.png" alt="No hay busquedas" width="440">
            <p class="text-center text-h6 font-weight-regular mt-6">Use los filtros para buscar expedientes por fase, tema y más.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>

export default {
  name: 'SearchView',
  components: {
  },
  created() {
    this.loadInitData();
  },
  computed: {
    isExternalUser(){
        if (localStorage.getItem('userRole') && localStorage.getItem('userRole') === '79f6fa11-a0d2-4663-8cdd-1edf545a335f') {
          return true;
        }
        return false
    },
    expedienteHasValue() {
      if (this.searchSelected.numeroExpediente && this.searchSelected.numeroExpediente !== '') {
        return true;
      }
      return false;
    },
  },
  data: () => ({
    isDownloading: false,
    searching: false,
    isSuperAdmin: false,
    isReadOnlyUser: false,
    agregarProyectoMenu: false,
    searchTable: '',
    headerTableProps: {
        sortByText: "Filtrar por"
    },
    headers: [
        {
          text: 'Expediente',
          align: 'start',
          sortable: true,
          value: 'expediente',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'nombre',
        }
      ],
    showResult: false,
    initData: {
      proponentes: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      estados: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      partidos: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      temas: [
        { key: 1, value: 'Peculado Financiero' },
        { key: 2, value: 'Teletrabajo' },
        { key: 3, value: 'Patentes' },
      ],
      coberturas: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      categorias: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      afectaciones: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      monitoreos: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      fases: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      subfases: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      subSubFases: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      plenarioSubfases: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      comisiones: [
        {
          key: "00000000-0000-0000-0000-000000000000",
          value: null,
          group: null,
        },
      ],
      comisionesTramite: [
        {
          key: "00000000-0000-0000-0000-000000000000",
          value: null,
          group: null,
        },
      ],
      comisionesSubComision: [
        {
          key: "00000000-0000-0000-0000-000000000000",
          value: null,
          group: null,
        },
      ],
      plenas: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      afectaA: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      legislaturas: [{
        key: "00000000-0000-0000-0000-000000000000",
        value: null,
      }],
      tipoFechas: [
        { key: 1, value: 'Fecha Presentation' },
        { key: 2, value: 'Vencimiento Cuatrienal' },
        { key: 3, value: 'Fecha para Dictaminar' },
        { key: 4, value: 'Plazo de Subcomisión' },
      ],
      puestaDespacho: false,
      convocado: false,
      archivado: false,
      consultado: false,
      viaRapida: false,
      dispensaTramite: false,
      consultadoMenu: false,
    },
    defaultSearch: {
      numeroExpediente: null,
      searchText: [''],
      faces: [],
      hasMoreFilters: false,
      moreFiltersIsOpen: false,
      proponente: null,
      tipoFecha: null,
      consultado: false,
      archivado: false,
      convocado: false,
      leyDeLaRepublica: false,
      dispensaTramite: false,
      puestaDespacho: false,
      afectaciones: null,
      coberturas: null,
      categorias: null,
      estados: null,
      comisiones: null,
      afectaA: null,
      partidos: null,
      desde: undefined,
      hasta: undefined,
    },
    searchSelected: {
      numeroExpediente: null,
      searchText: [''],
      faces: [],
      hasMoreFilters: false,
      moreFiltersIsOpen: false,
      proponente: null,
      tipoFecha: null,
      consultado: false,
      archivado: false,
      convocado: false,
      leyDeLaRepublica: false,
      dispensaTramite: false,
      puestaDespacho: false,
      afectaciones: null,
      coberturas: null,
      categorias: null,
      estados: null,
      comisiones: null,
      afectaA: null,
      partidos: null,
      desde: undefined,
      hasta: undefined,
    },
    resultSearch: [],
  }),
  watch: {
    searchSelected:{
      handler:(val) => {
        if(
        (val.temas && val.temas.length)
        || val.tipoFecha
        || val.consultado
        || val.archivado
        || val.convocado
        || val.leyDeLaRepublica
        || val.dispensaTramite
        || val.puestaDespacho
        || val.proponente
        || (val.afectaA && val.afectaA.length)
        || (val.comisiones && val.comisiones.length)
        || (val.afectaciones && val.afectaciones.length)
        || (val.coberturas && val.coberturas.length)
        || (val.estados && val.estados.length)
        || (val.partidos && val.partidos.length)
        || (val.hasta && val.hasta.length)
        || (val.desde && val.hasta.length)) {
          val.hasMoreFilters = true;
        } else {
          val.hasMoreFilters = false;
        }
      },
        deep: true
    }
  },
  mounted() {
    if (localStorage.getItem('userRole')) {
      this.isSuperAdmin = localStorage.getItem('userRole') === 'cfb41f8e-9aa3-4d18-8fe6-7198fa2a4cce';
      this.isReadOnlyUser = 
        localStorage.getItem('userRole') === 'a21a03cb-4c12-4b46-a527-e6a0506e636e' ||
        localStorage.getItem('userRole') === '79f6fa11-a0d2-4663-8cdd-1edf545a335f';
    }
    this.searchSelected = JSON.parse(localStorage.getItem('searchSelected')) || this.defaultSearch;

    if (JSON.stringify(this.searchSelected) !== JSON.stringify(this.defaultSearch)) {
      this.search();
    }
  },
  methods: {
    createUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, 
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    openProject(id) {
      if (this.isReadOnlyUser) {
        this.$router.push({ name: 'ReadOnlyInputProjectView', params: { id } })
      } else {
        this.$router.push({ name: 'ProjectView', params: { id } })
      }
    },
    addMoreSearchText() {
      this.searchSelected.searchText.push('');
    },
    removeMoreSearchText(index) {
      this.searchSelected.searchText = this.searchSelected.searchText.filter(
        (item, i) => i !== index
      );
    },
    search() {
      if (this.searchSelected.numeroExpediente === '') {
        this.searchSelected.numeroExpediente = null;
      }
      localStorage.setItem('searchSelected', JSON.stringify(this.searchSelected));
      this.searching = true;
      fetch('/api/Project/Search', {
        method: 'POST',
        body: JSON.stringify(this.searchSelected),
        headers: {
          'Authorization' : `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.searching = false;
          this.showResult = true;
          this.resultSearch = data;
        })
        .catch(error => {
          this.searching = false;
          console.error(error);
        });
    },
    searchDownload() {
      if (this.searchSelected.numeroExpediente === '') {
        this.searchSelected.numeroExpediente = null;
      }
      localStorage.setItem('searchSelected', JSON.stringify(this.searchSelected));
      this.isDownloading = true;
      fetch('/api/Project/SearchDownload', {
        method: 'POST',
        body: JSON.stringify(this.searchSelected),
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.blob())
        .then(response => {
            this.isDownloading = false;
            const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const downloadUrl = URL.createObjectURL(blob);
            const downaloadLink = document.createElement("a");
            downaloadLink.href = downloadUrl;
            downaloadLink.download = "ABC-Proyectos.xlsx";
            document.body.appendChild(downaloadLink);
            downaloadLink.click();
            document.body.removeChild(downaloadLink);
        })
        .catch(error => {
          this.isDownloading = false;
          this.searching = false;
          console.error(error);
        });
    },
    reset() {
      localStorage.setItem('searchSelected', null);
      this.searchSelected =  Object.assign({}, this.defaultSearch);
      this.resultSearch = [];
      this.showResult = false;
    },
    create(type) {

      this.$router.push({
        name: 'ProjectView',
        params: { 
          id: this.createUUID(),
          type
        }
      });
    },
    loadInitData() {
      var self = this;

      fetch('/api/Project/InitData', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          self.initData = data;
        })
        .catch(error => console.error(error));
    },
  },
};
</script>
