<style scoped>
.text-field-date-doc{
   padding: 0;
}
.v-application .v-btn.v-btn--disabled .white--text {
  color: gray !important;
}
</style>
<template>
    <div>
      <v-main class="white mt-1">
        <v-progress-linear
          :active="loadingProject"
          :indeterminate="loadingProject"
          absolute
          bottom
          color="primary accent-4"
        ></v-progress-linear>
        <v-scale-transition>
          <v-form class="form-project" v-model="isFormValid" lazy-validation v-if="!loadingProject" ref="formEdit">
            <div class="form-container">
              <v-container>
                <v-row class="pb-5">
                  <v-col>
                    <div class="header-actions d-flex justify-space-between mb-3">
                      <h2 class="text-blue-sky">Vista de Expediente</h2>
                    </div>
                    <div class="action-wrapper d-flex flex-column flex-sm-row">
                      <div>
                        <v-btn text class="label-input ml-4 ml-sm-2 pl-0 text-blue-sky" :disabled="!(currentProject.document && currentProject.document.url)"
                            target="_blank" :href="(currentProject.document && currentProject.document.url) ?
                        (isOfficeDoc(currentProject.document.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${currentProject.document.url}` : currentProject.document.url) : ''">
                          <v-icon left>
                            mdi-file-document-multiple-outline
                          </v-icon>
                          <span class="text-decoration-underline">Ver Documento Vigente</span>
                        </v-btn>
                      </div>
                      <div>
                        <v-dialog v-model="dresumen"
                                  width="1200">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="label-input ml-2 text-blue-sky" v-bind="attrs" v-on="on">
                              <v-icon left>
                                mdi-pencil-outline
                              </v-icon>
                              <span class="text-decoration-underline">Resumen</span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="text-h5 text-blue-sky lighten-2">
                              <h6 class="text-h6 mt-8 mb-4">Resumen</h6>
                              <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                <v-icon color="#1867C0" class="" @click="dresumen = false">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-tiptap v-model="currentProject.resumen" view />
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pb-8 px-6">
                              <v-btn color="#1867C0" outlined width="90" class="label-input"
                                    @click="dresumen = false">
                                Cerrar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dbitacora"
                                  width="1200">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="label-input ml-2 text-blue-sky"
                                  v-bind="attrs"
                                  v-on="on">
                              <v-icon left>
                                mdi-comment-outline
                              </v-icon>
                              <span class="text-decoration-underline">Bitácora</span>
                            </v-btn>
                          </template>
                          <v-card class="modal-max-h">
                            <v-card-title class="text-blue-sky lighten-2">
                              <h6 class="text-h6 mt-8 mb-4">Bitácora</h6>
                            </v-card-title>
                            <v-card-text>
                              <div class="list-container">
                                <template v-if="currentProject.bitacoras && currentProject.bitacoras.length">
                                <v-card class="mt-3 pa-6" v-for="(item, i) in currentProject.bitacoras" :key="i">
                                  <div class="d-flex justify-space-between mb-3">
                                    <div class="text-body-2">
                                      <p class="mb-0 text-blue-sky font-weight-bold">{{ item.userName }}</p>
                                      <span class="text-gray-500"> {{ item.date }}</span>
                                    </div>
                                  </div>
                                  <v-tiptap v-model="item.comment" view />
                                </v-card>
                              </template>
                              <template v-else>
                                <p>
                                  No hay resultados encontrados.
                                </p>
                              </template>
                              </div>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pb-5 px-6">
                              <v-btn color="#1867C0" outlined width="160" class="label-input"
                                    @click="isEditView = false;dbitacora = false" v-if="!bitacoraEditor.userName">
                                Salir
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                      <div>
                        <v-chip v-if="currentProject.estado && currentProject.estado.key == '63372ea0-f3e4-11ed-a4e0-025631b309b0'"
                                class="mx-2"
                                color="teal"
                                text-color="white">
                          Activo
                        </v-chip>
                        <v-chip v-else-if="currentProject.estado && currentProject.estado.key == '634e488c-f3e4-11ed-a4e0-025631b309b0'"
                                class="mx-2"
                                color="gray"
                                text-color="gray">
                          Inactivo
                        </v-chip>
                        <v-chip v-else-if="currentProject.estado && currentProject.estado.key == '636535f0-f3e4-11ed-a4e0-025631b309b0'"
                                class="mx-2"
                                color="red"
                                text-color="white">
                          Archivado
                        </v-chip>
                        <v-chip v-else-if="currentProject.estado && currentProject.estado.key == '636535f0-f3e4-11ed-a4e0-025631b309b1'"
                                class="mx-2"
                                color="red"
                                text-color="white">
                          Terminado
                        </v-chip>
                      </div>
                    </div>
                    <section class="info-wrapper mt-8">
                      <h3 class="font-weight-bold text-h6 text-blue-sky">
                        Información General
                      </h3>
                      <v-row class="mt-5">
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Número</label>
                          <v-text-field readonly v-model.number="currentProject.expediente" class="solo-border" type="number" placeholder="Número" flat single-line solo></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <label class="label-input mb-2 d-inline-block">Nombre
                            <v-icon left v-bind:title="currentProject.nombre">
                                mdi-information-variant-circle-outline
                            </v-icon>
                          </label>
                          <v-text-field readonly v-bind:title="currentProject.nombre" v-model="currentProject.nombre" class="solo-border" placeholder="Nombre" flat single-line solo></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="my-0 mt-2">
                        <v-col cols="12" sm="4" v-if="!isExternalUser">
                          <label class="label-input mb-2 d-inline-block">Conocido como</label>
                          <v-text-field readonly v-model="currentProject.alias" class="solo-border full-height-input" placeholder="Conocido como" flat solo></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="isExternalUser">
                          <label class="label-input mb-2 d-inline-block">Partido</label>
                          <v-combobox class="solo-border" v-model="currentProject.partido"
                                              flat solo readonly
                                              :items="initData.partidos"
                                              item-value="key"
                                              item-text="value"
                                              return-object></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <label class="label-input mb-2 d-inline-block">Proponente
                            <v-icon left v-bind:title="displayList(currentProject.proponente)">
                                mdi-information-variant-circle-outline
                            </v-icon>
                          </label>
                          <v-combobox class="solo-border" multiple required
                                      readonly
                                      placeholder="Proponente"
                                      flat
                                      chips
                                      v-model="currentProject.proponente"
                                      :items="initData.proponentes"
                                      item-value="key"
                                      item-text="value"
                                      :rules="[v => !!v || 'Este valor requerido']"
                                      return-object
                                      solo>
                                      <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index < 4">
                                          <span>{{ item.value }}</span>
                                        </v-chip>
                                        <v-chip v-if="index === 4">
                                          <span> (+{{ currentProject.proponente.length - 4 }})</span>
                                        </v-chip>
                                      </template>
                                    </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row class="my-0" v-if="!isExternalUser">
                        <v-col cols="12" sm="4">
                          <label class="label-input mb-2 d-inline-block">Partido</label>
                          <v-combobox class="solo-border" v-model="currentProject.partido"
                                              flat solo readonly
                                              :items="initData.partidos"
                                              item-value="key"
                                              item-text="value"
                                              return-object></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <label class="label-input mb-2 d-inline-block">Tema</label>
                          <v-text-field readonly
                          v-model="currentProject.tema" class="solo-border" placeholder="Tema" flat single-line solo></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <label class="label-input mb-2 d-inline-block">Cobertura</label>
                          <v-combobox readonly
                          class="solo-border" v-model="currentProject.cobertura"
                                              flat solo
                                              :items="initData.coberturas"
                                              item-value="key"
                                              item-text="value"
                                              return-object></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row v-if="!isExternalUser">
                        <v-col cols="12" sm="12" md="4">
                          <label class="label-input mb-2 d-inline-block">Categoría</label>
                          <v-combobox readonly
                            v-model="currentProject.categorias"
                            class="solo-border" flat solo multiple
                            :items="initData.categorias"
                            item-value="key"
                            item-text="value"
                            return-object>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <label class="label-input mb-2 d-inline-block">Afectación</label>
                          <v-combobox readonly
                          v-model="currentProject.afectacion"
                            class="solo-border" flat solo
                            :items="initData.afectaciones"
                            item-value="key"
                            item-text="value"
                            return-object>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <label class="label-input mb-2 d-inline-block">Afecta A:</label>
                          <v-combobox readonly
                          v-model="currentProject.afectaA"
                            class="solo-border" flat solo
                            :items="initData.afectaA"
                            item-value="key"
                            item-text="value"
                            return-object>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </section>
                    <section class="info-wrapper mt-8">
                      <h3 class="font-weight-bold text-h6 text-blue-sky">
                        {{currentProject.type === 'tramiteOrdinario' ? 'Trámite Ordinario':'Trámite Reforma Constitucional'}}
                      </h3>
                      <v-row class="mt-5">
                        <v-col cols="6" sm="3">
                          <label class="label-input mb-2 d-inline-block">Fecha Presentación</label>
                          <v-menu disabled
                          v-model="currentProject.fechaPresentacionMenu" min-width="auto" offset-y
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field readonly
                                class="solo-border" v-model="currentProject.fechaPresentacionValue"
                                              placeholder="Fecha Presentación"
                                              required
                                              :rules="[v => !!v || 'Este valor requerido']"
                                              flat single-line solo prepend-inner-icon="mdi-calendar"
                                              v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="currentProject.fechaPresentacionValue"
                                              @input="currentProject.fechaPresentacionMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <label class="label-input mb-2 d-inline-block">Vencimiento Cuatrienal</label>
                          <v-menu disabled
                          v-model="currentProject.vencimientoCuatrienalMenu" min-width="auto" offset-y
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field readonly class="solo-border" v-model="currentProject.vencimientoCuatrienalValue"
                                              placeholder="Vencimiento Cuatrienal" required
                                              :rules="[v => !!v || 'Este valor requerido']"
                                              flat single-line solo prepend-inner-icon="mdi-calendar"
                                              v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="currentProject.vencimientoCuatrienalValue"
                                              @input="currentProject.vencimientoCuatrienalMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="d-flex flex-wrap pt-4 checkbox-wrapper" cols="12" sm="6">
                          <v-checkbox readonly
                            class=""
                            v-model="currentProject.puestaDespacho"
                            label="Puesta a despacho"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox readonly
                            v-model="currentProject.dispensaDeTramite"
                            label="Dispensa de Trámite"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox readonly
                            v-model="currentProject.tieneMociones"
                            label="Tiene Mociones"
                            color="#1867C0"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox readonly
                            v-model="currentProject.viaRapida"
                            label="Vía Rápida"
                            color="#1867C0"
                            hide-details
                          >
                          <template v-slot:append v-if="currentProject.viaRapida">
                            <v-dialog v-model="dviaRapida" width="1000">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn min-width="20" class="pa-0 mt-n2" icon color="black" v-bind="attrs"
                                      v-on="on">
                                  <v-icon color="#1867C0">
                                    mdi-text-box-edit-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title class="p-relative">
                                  <h6 class="text-h6 mt-8">Vía Rápida Resumen</h6>
                                  <v-btn min-width="20" class="pa-0 right-0 p-absolute mr-4" icon color="black">
                                    <v-icon color="#1867C0" class="" @click="dviaRapida = false">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text>
                                  <v-tiptap v-model="currentProject.viaRapidaResumen" view />
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end pb-8 px-6">
                                  <v-btn color="#1867C0" outlined width="90" class="label-input"
                                        @click="dviaRapida = false">
                                    Salir
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                          </v-checkbox>
                          <span class="text-no-wrap" v-bind:class="{'cursor-disabled': initData.isOrdinaria }">
                            <v-checkbox readonly
                              v-bind:class="{'cursor-disabled': initData.isOrdinaria }"
                              v-model="currentProject.convocado"
                              label="Convocado"
                              color="#1867C0"
                              hide-details
                              ></v-checkbox>
                          </span>
                          <v-checkbox
                            v-model="currentProject.consultado"
                            readonly
                            v-bind:label="`Consultado ABC ${currentProject.consultados && currentProject.consultados.length > 0 ? `(${currentProject.consultados.length})` : ''}`"
                            color="#1867C0"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <label class="label-input mb-2 d-inline-block">Fase</label>
                          <v-combobox readonly v-model="currentProject.fase"
                              class="solo-border" flat solo
                              :items="[]"
                              item-value="key"
                              item-text="value"
                              return-object>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <template v-if="currentProject.fase && currentProject.fase.value == 'Comisión'">
                            <label class="label-input mb-2 d-inline-block">Subfase</label>
                            <v-combobox readonly v-model="currentProject.subfase"
                              :items="initData.subfases"
                              class="solo-border" flat solo
                              item-value="key"
                              item-text="value"
                              return-object></v-combobox>
                          </template>
                          <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                            <label class="label-input mb-2 d-inline-block">Subfase</label>
                            <v-combobox readonly v-model="currentProject.plenarioSubfase"
                              class="solo-border" flat solo
                              :items="initData.plenarioSubfases"
                              item-value="key"
                              item-text="value"
                              return-object></v-combobox>
                          </template>
                          <template v-if="currentProject.fase.value == 'Comisiones Plenas'">
                            <label class="label-input mb-2 d-inline-block">Plenas</label>
                            <v-combobox readonly v-model="currentProject.plena" flat solo
                                :items="initData.plenas"
                                item-value="key"
                                item-text="value"
                                class="solo-border"
                                return-object>
                            </v-combobox>
                        </template>
                        </v-col>
                        <template v-if="(currentProject.plenarioSubfase && currentProject.fase) && currentProject.fase.value == 'Plenario' && currentProject.plenarioSubfase.value == 'Primer Debate'">
                          <v-col cols="12" sm="3">
                            <label class="label-input mb-2 d-inline-block">Subfase 2</label>
                            <v-combobox readonly
                              v-model="currentProject.subSubFase"
                              class="solo-border" flat solo
                              :items="initData.subSubFases"
                              item-value="key"
                              item-text="value"
                              return-object>
                            </v-combobox>
                          </v-col>
                          <v-col cols="12" sm="3" v-if="!isExternalUser">
                            <label class="label-input mb-2 d-inline-block">Monitoreo</label>
                            <v-combobox readonly v-model="currentProject.monitoreo"
                                class="solo-border" flat solo
                                :items="initData.monitoreos"
                                item-value="key"
                                item-text="value"
                                return-object>
                            </v-combobox>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col cols="12" sm="6" v-if="!isExternalUser">
                            <label class="label-input mb-2 d-inline-block">Monitoreo</label>
                            <v-combobox readonly v-model="currentProject.monitoreo"
                                class="solo-border" flat solo
                                :items="initData.monitoreos"
                                item-value="key"
                                item-text="value"
                                return-object>
                            </v-combobox>
                          </v-col>
                        </template>
                        <template v-if="currentProject.fase && currentProject.fase.value == 'Plenario'">
                            <v-col cols="12" sm="6">
                              <label class="label-input mb-2 d-inline-block">Comisión</label>
                              <v-combobox readonly v-model="currentProject.comision"
                                          :items="initData.comisiones"
                                          class="solo-border" flat solo
                                          item-value="key"
                                          item-text="value"
                                          return-object>
                              </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <label class="label-input mb-2 d-inline-block">Orden del día</label>
                              <v-text-field readonly type="number" flat solo :disabled="isArchivado || isProjectLeyRepublica"
                                            class="solo-border"
                                            min="1"
                                            v-model="currentProject.ordenSubcomision">
                              </v-text-field>
                            </v-col>
                          </template>
                        <template v-if="currentProject.fase.value == 'Comisión' && currentProject.subfase.value == 'Trámite'">
                          <v-col cols="12" sm="6">
                            <label class="label-input mb-2 d-inline-block">Comisión</label>
                            <v-combobox v-model="currentProject.comision" flat solo readonly
                                        class="solo-border"
                                        :items="initData.comisiones"
                                        item-value="key"
                                        item-text="value"
                                        return-object >
                              <template v-slot:selection="data">
                                {{ data.item.value }}
                              </template>
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content class="ml-6">
                                    <v-list-item-title v-html="data.item.value"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <label class="label-input mb-2 d-inline-block">Orden del día</label>
                            <v-text-field type="number" flat solo readonly
                                          class="solo-border"
                                          min="1"
                                          v-model="currentProject.ordenSubcomision">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-menu readonly v-model="currentProject.plazoDictaminarSubcomisionMenu" disabled min-width="auto" offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <label class="label-input mb-2 d-inline-block">Plazo para Dictaminar</label>
                                <v-text-field readonly v-model="currentProject.plazoDictaminarSubcomision"
                                              prepend-inner-icon="mdi-calendar" flat solo
                                              class="solo-border"
                                              v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="currentProject.plazoDictaminarSubcomision"
                                            @input="currentProject.plazoDictaminarSubcomisionMenu = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                        </template>
                        <template v-if="currentProject.fase.value == 'Comisión' && currentProject.subfase.value == 'Subcomisión'">
                          <v-col cols="12" sm="6">
                            <label class="label-input mb-2 d-inline-block">Comisión</label>
                            <v-combobox readonly v-model="currentProject.comision" flat solo
                                        class="solo-border" chips
                                        :items="initData.comisiones"
                                        item-value="key"
                                        item-text="value"
                                        return-object >
                              <template v-slot:selection="data">
                                {{ data.item.value }}
                              </template>
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content class="ml-6">
                                    <v-list-item-title v-html="data.item.value"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="6" md="3">
                            <label class="label-input mb-2 d-inline-block">Orden del dia</label>
                            <v-text-field type="number" flat solo readonly
                                          class="solo-border"
                                          min="1"
                                          v-model="currentProject.ordenSubcomision"></v-text-field>
                          </v-col>
                          <v-col cols="6" md="3">
                            <v-menu readonly v-model="currentProject.plazoDictaminarSubcomisionMenu" disabled min-width="auto" offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <label class="label-input mb-2 d-inline-block">Plazo para Dictaminar</label>
                                <v-text-field readonly v-model="currentProject.plazoDictaminarSubcomision"
                                              prepend-inner-icon="mdi-calendar" flat solo
                                              class="solo-border"
                                              v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="currentProject.plazoDictaminarSubcomision"
                                            @input="currentProject.plazoDictaminarSubcomisionMenu = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6" md="9">
                            <label class="label-input mb-2 d-inline-block">SubComision
                              <v-icon left v-bind:title="displayList(currentProject.proponenteSubcomision)">
                                mdi-information-variant-circle-outline
                              </v-icon>
                            </label>
                            <v-combobox readonly v-model="currentProject.proponenteSubcomision"
                                        :items="subComisionDiputados" chips
                                        multiple
                                        item-value="key"
                                        item-text="value"
                                        class="solo-border" flat solo>
                                        <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index < 4">
                                          <span>{{ item.value }}</span>
                                        </v-chip>
                                        <v-chip v-if="index === 4">
                                          <span> (+{{ currentProject.proponenteSubcomision.length - 4 }})</span>
                                        </v-chip>
                                      </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="6" md="3">
                            <v-menu readonly v-model="currentProject.plazoSubcomisionMenu" min-width="auto" offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <label class="label-input mb-2 d-inline-block">Plazo de Subcomisión</label>
                                <v-text-field readonly v-model="currentProject.plazoSubcomision"
                                              prepend-inner-icon="mdi-calendar" flat solo
                                              class="solo-border"
                                              v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker day-format="" v-model="currentProject.plazoSubcomision"
                                            @input="currentProject.plazoSubcomisionMenu = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                        </template>
                      </v-row>
                    </section>
                    <v-divider class="my-8"></v-divider>
                  <section class="info-wrapper">
                    <div class="d-flex justify-space-between align-center">
                      <h3 class="font-weight-bold text-h6 text-blue-sky">
                        Documentos
                      </h3>
                    </div>
                    <v-text-field
                      v-model="searchDocTable"
                      append-icon="mdi-magnify"
                      label="Filtar"
                      class="px-0"
                      clearable
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-data-table
                        class="text-body-1"
                        :header-props="headerDocTableProps"
                        locale="es-ES"
                        :headers="headersDoc"
                        :items="currentProject.documentos"
                        :search="searchDocTable"
                        :footer-props="{
                            'items-per-page-text':'Documentos por pagina',
                            'items-per-page-options': [
                              20,
                              40,
                              60,
                              -1
                            ]
                        }"
                      >
                      <template v-slot:item="row">
                          <tr>
                            <td >
                              <v-icon color="#1867C0" v-text="getDocIcon(row.item.text)"></v-icon>
                            </td>
                            <td class="text-black cursor-pointer hover-underline">
                              <a class="text-black" target="_blank" :href="isOfficeDoc(row.item.text) ? `https://view.officeapps.live.com/op/embed.aspx?src=${row.item.url}` : row.item.url">
                                <span class="">
                                  {{ row.item.text }}
                                </span>
                              </a>
                            </td>
                            <td class="text-black">
                                <span class="">
                                  {{ row.item.fecha }}
                                </span>
                            </td>
                            <td class="d-flex justify-end align-center">
                              <v-btn icon>
                                  <a @click="openDialog(
                                              isOfficeDoc(row.item.text)
                                                  ? `https://view.officeapps.live.com/op/embed.aspx?src=${row.item.url}`
                                                  : row.item.url
                                          )">
                                  <v-icon color="#1867C0" >
                                    mdi-eye
                                  </v-icon>
                                </a>
                              </v-btn>
                              <v-btn icon>
                                  <a class="" v-bind:href="row.item.url" download>
                                    <v-icon color="#1867C0" >
                                    mdi-cloud-download
                                    </v-icon>
                                  </a>
                              </v-btn>
                            </td>
                          </tr>
                      </template>
                    </v-data-table>
                  </section>
                  </v-col>
                </v-row>
                <v-dialog v-model="viewDocDialog" width="1200">
                  <v-card>
                      <v-card-title class="headline">Documento</v-card-title>
                      <v-card-text style="height: 70vh">
                          <iframe :src="urlDocDialog" width="100%" height="100%"></iframe>
                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="viewDocDialog = false">Cerrar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
              </v-container>
            </div>
            <div class="border-top">
              <v-container>
                <div class="d-flex justify-end">
                  <v-btn text class="label-input" @click="close()">
                    <span class="font-weight-bold">Volver busqueda</span>
                  </v-btn>
                </div>
              </v-container>
            </div>
          </v-form>
        </v-scale-transition>
      </v-main>
    </div>
</template>
<script>
  import { format } from 'date-fns'

  export default {
    name: 'ReadOnlyInputProjectView',
    components: {
    },
    data: () => ({
      urlDocDialog: '',
      viewDocDialog: false,
      searchDocTable: '',
      headerDocTableProps: {
        sortByText: "Filtrar por"
      },
      headersDoc: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'text',
        },
        {
          text: 'Fecha del Documento',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        {
          text: '',
          align: 'end',
          sortable: false
        }
      ],
      loadingProject: false,
      isFormValid: false,
      isSelecting: false,
      // Bitacora:
      bitacoraCurrentIndex: null,
      dateBitacoraMenu: false,
      isEditView: false,
      bitacoraEditor: {
        userName: "",
        text: "",
        date: null
      },
      currentDoc:{
        url: ''
      },
      currentProject: {
        id: "00000000-0000-0000-0000-000000000000",
        expediente: 0,
        alias: "",
        nombre: "",
        estado: null,
        textoVigente: {},
        archivado: false,
        proponente: null,
        partido: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        tema: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        cobertura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        categorias: [{
          key: "00000000-0000-0000-0000-000000000000",
          value: ""
        }],
        afectacion: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        fechaPresentacionMenu: false,
        fechaPresentacionValue: null,
        vencimientoCuatrienalMenu: false,
        vencimientoCuatrienalValue: null,
        monitoreo: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        fase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        subSubFase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plenarioSubfase: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        legislatura: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        plena: {
          key: "00000000-0000-0000-0000-000000000000",
          value: null
        },
        comision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionTramite: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        comisionSubComision: {
          key: null,
          value: null,
          group: null,
          header: null,
          divider: null
        },
        ordenTramite: 0,
        puestaDespacho: false,
        convocado: false,
        consultado: false,
        viaRapida: false,
        viaRapidaResumen: '',
        dispensaDeTramite: false,
        tieneMociones: false,
        plazoDictaminarTramiteMenu: false,
        plazoDictaminarTramite: null,
        plazoDictaminarSubcomisionMenu: false,
        plazoDictaminarSubcomision: null,
        ordenSubcomision: 0,
        proponenteSubcomision: [

        ],
        plazoSubcomision: null,
        estados: [

        ],
        consultados: [

        ],
        documentos: [

        ],
        bitacora: [

        ],
        resumen: ""
      },
      initData: {},
      dresumen: false,
      dbitacora: false,
      dconsultado: false,
      dviaRapida: false,
      valid: false,
      bitacora: [{
        personName: "",
        text: "",
        date: null
      }],
      resumen: "",
    }),
    created() {
    },
    mounted() {
      this.loadProject();
      this.docDate = format(new Date(), 'yyyy-MM-dd');
    },
    computed: {
      isExternalUser(){
        if (localStorage.getItem('userRole') && localStorage.getItem('userRole') === '79f6fa11-a0d2-4663-8cdd-1edf545a335f') {
          return true;
        }
        return false
      }
    },
    methods: {
      openDialog(url) {
            this.urlDocDialog = url;
            this.viewDocDialog = true;
        },
      displayList(list) {
        if(list && list.length){
          return list.map((obj) => obj.value).join(', \n')
        }
        return '';
      },
      getDocIcon(filename) {
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'pdf':
            return 'mdi-file-pdf-box';
            case 'doc':
            case 'docx':
            return 'mdi-file-word';
            case 'xls':
            case 'xlsx':
              return 'mdi-microsoft-excel';
            default:
              return 'mdi-file-document-outline';
          }
        }
      },
      isOfficeDoc(filename){
        if(filename && filename.indexOf('.' > 0)){
          const extFile = filename.split('.').pop().toLowerCase();
          switch(extFile){
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
              return true;
            default:
              return false;
          }
        }
        return false;
      },
      loadProject() {
        var self = this;
        this.loadingProject = true;
        fetch('/api/Project/' + this.$route.params.id, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            self.currentProject = data;
            if (this.$route.params.type) {
              self.currentProject.type = this.$route.params.type;
            }
            this.loadingProject = false;
            // self.currentProject.documentos[0].isCurrentDocument = true;
          })
          .catch(error => {console.error(error)});
      },
      close() {
        this.$router.push({ name: 'SearchView' })
      }
    }
};
</script>
