<template>
  <div class="search-container d-flex h-full flex-column">
    <v-main class="bg-ligth-blue pb-6 h-full">
      <v-container>
        <div class="p-absolute alert-container right-5 w-full">
          <div class="alert-item alert--success" v-if="saveSuccessAlert">
            <img src="check-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Guardado con éxito.</h6>
              <p>Las ediciones han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="alert-item alert--error" v-if="saveErrorAlert">
            <img src="error-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Error al guardar.</h6>
              <p>Las ediciones no han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveErrorAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="pt-5">
          <v-col cols="6" class="mx-auto" v-if="userData">
            <v-card class="py-4">
                    <v-form ref="formNewUser" v-model="formNewUserValid">
                      <v-card-title class="text-h5">
                       Perfil
                      </v-card-title>
                      <v-card-text>
                        <label class="label-input mb-2 d-inline-block">Nombre</label>
                        <v-text-field
                          required
                          :rules="requiredInput"
                          class="solo-border"
                          v-model="userData.userFirstName"
                          placeholder="Nombre"
                          flat single-line solo>
                        </v-text-field>
                        <label class="label-input mb-2 d-inline-block">Apellido</label>
                        <v-text-field
                          required
                          :rules="requiredInput"
                          class="solo-border"
                          v-model="userData.userLastName"
                          placeholder="Apellido"
                          flat single-line solo>
                        </v-text-field>
                        <label class="label-input mb-2 d-inline-block">Email</label>
                        <v-text-field
                          required
                          :rules="emailRules"
                          :disabled="isEditing"
                          class="solo-border"
                          v-model="userData.userEmail"
                          placeholder="Email"
                          flat single-line solo>
                        </v-text-field>
                        <v-dialog
                            v-model="editPassword"
                            persistent
                            max-width="450"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn text class="label-input px-2 text-blue-sky" v-bind="attrs" v-on="on"  @click="openResetPassword()">
                                <v-icon
                                  left
                                  dark
                                  color="#1867C0"
                                >{{ 'mdi-lock' }}
                                </v-icon>
                                <span class="text-blue-sky">
                                  Cambiar Contraseña.
                                </span>
                              </v-btn>
                            </template>
                            <v-card class="py-4">
                              <v-card-title class="text-h5">
                                Cambiar Contraseña
                              </v-card-title>
                              <v-card-text>
                                  <v-text-field
                                    v-model="newPassword1"
                                    :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rulesPassword.required, rulesPassword.min]"
                                    :type="showPassword1 ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Contraseña nueva"
                                    hint="minimo 8 caracteres"
                                    counter
                                    @click:append="showPassword1 = !showPassword1"
                                  ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="d-flex px-6">

                                <v-spacer v-if="!isEditing"></v-spacer>
                                <v-btn color="#1867C0" outlined class="label-input"
                                      @click="editPassword = false;showPassword1 = null;">
                                      Cancelar
                                </v-btn>
                                <v-btn class="label-input" color="primary"
                                    :disabled="!newPassword1 || newPassword1.length < 8"
                                    @click="savePassword(); editPassword = false">
                                    Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                      </v-card-text>
                      <v-card-actions class="d-flex px-4">
                        <v-btn class="label-input" color="primary"
                            :disabled="!userData.userFirstName && userData.userLastName"
                            @click="saveItem()">
                            Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
              </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>

export default {
  name: 'ProfileView',
  components: {
  },
  created() {
    this.loadUser();
  },
  data: () => ({
    requiredInput: [
        v => !!v || 'valor es requerido',
    ],
    rulesPassword: {
      required: value => !!value || 'Requerido.',
      min: v => v && v.length >= 8 || 'Minimo 8 caracteres'
    },
    formNewUserValid: false,
    formUsersValid: false,
    showPassword1: false,
    newPassword1: null,
    newPassword2: null,
    editPassword: false,
    emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido'
    ],
    saveSuccessAlert: false,
    saveErrorAlert: false,
    isEditing: true,
    parentData: null,
    dataTableisLoaded: false,
    userData: {},
    currentValue: {},
    initData: {},
  }),
  watch: {
    saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
  },
  methods: {
    saveItem() {
      if (this.$refs.formNewUser.validate()) {
        this.currentValue = this.userData;
          fetch(`/api/user/${this.currentValue.id}`, {
          method: 'PUT',
          body: JSON.stringify({
            ...this.currentValue
          }),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(data => {
          this.saveSuccessAlert = true;
        })
        .catch(error =>{
          this.saveErrorAlert = true;
        });
        this.currentValue = {};
      }
    },
    openResetPassword() {
      this.editPassword = true;
      this.password1 = null;
    },
    savePassword(){
      fetch(`/api/user/changepassword`, {
          method: 'PUT',
          body: JSON.stringify({
            password: this.newPassword1
          }),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(response => console.log('Password changed'))
        .catch(error => console.error(error));
    },
    loadUser() {
      fetch('/api/User', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.userData = data;
        })
        .catch(error => console.error(error));
    },
  },
};
</script>
