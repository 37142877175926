<template>
  <div class="search-container d-flex h-full flex-column">
    <v-main class="bg-ligth-blue pb-6 h-full">
      <v-container>
        <div class="p-absolute alert-container right-5 w-full">
          <div class="alert-item alert--success" v-if="saveSuccessAlert">
            <img src="check-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Guardado con éxito.</h6>
              <p>Las ediciones han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="alert-item alert--error" v-if="saveErrorAlert">
            <img src="error-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Error al guardar.</h6>
              <p>Las ediciones no han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveErrorAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="pt-5">
          <v-col cols="12" v-if="usersData && dataTableisLoaded">
              <v-card class="h-full pa-3">
                <v-card-title>
                  Usuarios
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Filtar"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-dialog
                  v-model="editModal"
                  persistent
                  max-width="450"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn  @click="editModal = true; isEditing = false;" v-bind="attrs"
                      v-on="on" class="label-input ml-10" color="#3C77AF">
                      <span class="white--text">Crear Nuevo</span>
                    </v-btn>
                  </template>
                  <v-card class="py-4">
                    <v-form ref="formNewUser" v-model="formNewUserValid">
                      <v-card-title class="text-h5">
                        {{ isEditing ? 'Editar': 'Crear' }}
                      </v-card-title>
                      <v-card-text>
                        <label class="label-input mb-2 d-inline-block">Nombre</label>
                        <v-text-field
                          required
                          :rules="requiredInput"
                          class="solo-border"
                          v-model="currentValue.userFirstName"
                          placeholder="Nombre"
                          flat single-line solo>
                        </v-text-field>
                        <label class="label-input mb-2 d-inline-block">Apellido</label>
                        <v-text-field
                          required
                          :rules="requiredInput"
                          class="solo-border"
                          v-model="currentValue.userLastName"
                          placeholder="Apellido"
                          flat single-line solo>
                        </v-text-field>
                        <label class="label-input mb-2 d-inline-block">Email</label>
                        <v-text-field
                          required
                          :rules="emailRules"
                          :disabled="isEditing"
                          class="solo-border"
                          v-model="currentValue.userEmail"
                          placeholder="Email"
                          flat single-line solo>
                        </v-text-field>
                        <div v-if="!isEditing">
                          <label class="label-input mb-2 d-inline-block">Contraseña</label>
                          <v-text-field
                            ref="formPassword"
                            v-model="currentValue.password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rulesPassword.required, rulesPassword.min]"
                            :type="showPassword ? 'text' : 'password'"
                            name="input-10-1"
                            label="Contraseña nueva"
                            hint="minimo 8 caracteres" solo 
                            @click:append="showPassword = !showPassword"
                          ></v-text-field>
                        </div>
                        <label class="label-input mb-2 d-inline-block">Rol</label>
                        <v-select solo
                          required
                          :rules="requiredInput"
                          v-model="currentValue.roleId"
                          :items="rolList"
                          item-value="id"
                          item-text="name"
                        ></v-select>
                        <label class="label-input mb-2 d-inline-block">Aplicaciones</label>
                        <v-combobox class="solo-border" multiple :disabled="expedienteHasValue" clearable
                              placeholder="Aplicaciones"
                              chip
                              v-model="currentValue.apps"
                              :items="appList"
                              item-value="id"
                              item-text="name"
                              solo></v-combobox>
                      </v-card-text>
                      <v-card-actions class="d-flex px-6">
                        <v-btn v-if="!currentValue.isDeleted && isEditing" class="label-input mr-auto" color="#E83656" @click="saveItem(true)">
                          <v-icon left class="white--text">
                            mdi-trash-can-outline
                          </v-icon>
                          <span class="white--text"> Eliminar</span>
                        </v-btn>
                        <v-btn v-if="currentValue.isDeleted" class="label-input mr-auto" color="#1867C0" @click="saveItem(false)">
                          <span class="white--text">Activar</span>
                        </v-btn>
                        <v-spacer v-if="!isEditing"></v-spacer>
                        <v-btn color="#1867C0" outlined class="label-input"
                              @click="editModal = false; isEditing = false">
                              Cancelar
                        </v-btn>
                        <v-btn class="label-input" color="primary"
                            :disabled="!formNewUserValid"
                            @click="saveItem()">
                            {{ isEditing ? 'Guardar' : 'Crear'}}
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                </v-card-title>
                <v-form ref="formUsers" v-model="formUsersValid" >
                  <v-data-table
                  class="px-4"
                    :headers="headers"
                    :items="usersData"
                    :item-class="isRowDeleted"
                    :search="search"
                    @click:row="editValue"
                  >
                  <template v-slot:item="row">
                      <tr v-bind:class="{'is-deleted': row.item.isDeleted }" @click="editValue(row.item)">
                        <td>{{row.item.userFirstName}} {{row.item.userLastName}}</td>
                        <td>{{row.item.userEmail}}</td>
                        <td>{{getRolName(row.item.roleId)}}</td>
                        <td class="d-flex justify-end">
                          <v-dialog
                            v-model="row.item.editPassword"
                            persistent
                            max-width="450"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="openResetPassword()" v-bind="attrs"
                        v-on="on" dark icon class="mt-1" color="#1867C0" title="Cambiar Contraseña">
                                <v-icon alt>
                                  mdi-key-variant
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-card class="py-4">
                              <v-card-title class="text-h5">
                                Cambiar Contraseña
                              </v-card-title>
                              <v-card-text>
                                  <v-text-field
                                    ref="formPassword"
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rulesPassword.required, rulesPassword.min]"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Contraseña nueva"
                                    hint="minimo 8 caracteres"
                                    counter
                                    @click:append="showPassword = !showPassword"
                                  ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="d-flex px-6">
                                <v-btn v-if="!currentValue.isDeleted && isEditing" class="label-input mr-auto" color="#E83656" @click="saveItem(true)">
                                  <v-icon left class="white--text">
                                    mdi-trash-can-outline
                                  </v-icon>
                                  <span class="white--text"> Eliminar</span>
                                </v-btn>
                                <v-btn v-if="currentValue.isDeleted" class="label-input mr-auto" color="#1867C0" @click="saveItem(false)">
                                  <span class="white--text">Activar</span>
                                </v-btn>
                                <v-spacer v-if="!isEditing"></v-spacer>
                                <v-btn color="#1867C0" outlined class="label-input"
                                      @click="row.item.editPassword = false;">
                                      Cancelar
                                </v-btn>
                                <v-btn class="label-input" color="primary"
                                    :disabled="!formUsersValid"
                                    @click="savePassword(row.item.id); row.item.editPassword = false">
                                    Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </td>
                      </tr>
                  </template>
                  </v-data-table>
                </v-form>
              </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>

export default {
  name: 'UsersView',
  components: {
  },
  computed: {
      getRolName() {
        return (idRol) => {
          if(this.rolList.length) {
            const rolname = this.rolList.find((rol) => rol.id === idRol);
            return rolname.name;
          }
          return '';
        }
      }
  },
  created() {
    this.loadInitData();
  },
  data: () => ({
    requiredInput: [
        v => !!v || 'valor es requerido',
    ],
    formNewUserValid: false,
    formUsersValid: false,
    showPassword: false,
    password: '',
    rulesPassword: {
      required: value => !!value || 'Required.',
      min: v => v && v.length >= 8 || 'Min 8 characters',
    },
    emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido'
    ],
    editPassword: false,
    saveSuccessAlert: false,
    saveErrorAlert: false,
    fechaSistemMenu: false,
    diputadosList: [],
    rolList: [],
    appList: [],
    editModal: false,
    isEditing: false,
    parentData: null,
    usersDataFiltered: null,
    dataTableisLoaded: false,
    usersData: [],
    currentValue: {},
    search: '',
    headers: [
        {
          text: 'Nombre Completo',
          align: 'start',
          sortable: true,
          value: 'userFirstName',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'userEmail',
        },
        {
          text: 'Rol',
          align: 'start',
          sortable: false
        },
        {
          text: '',
          align: 'end',
          sortable: false
        }
      ],
    categoryData: null,
    currentList: '0',
    parentSelected: null,
    dataLabels: [
    ],
    initData: {},
  }),
  watch: {
    saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
  },
  methods: {
    createUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, 
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    editValue(item) {
      this.currentValue = null;
      this.editModal = true;
      this.isEditing = false;
      if (item) {
        this.isEditing = true;
        this.currentValue = { ...item };
      }
    },
    openResetPassword() {
      this.editPassword = true;
      this.password = '';
      if (this.$refs.formUsers){
        this.$refs.formUsers.resetValidation();
      }
    },
    savePassword(idUser){
      fetch(`/api/user/${idUser}/changepassword`, {
          method: 'PUT',
          body: JSON.stringify({
            password: this.password
          }),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(response => this.loadUsers())
        .catch(error => console.error(error));
    },
    saveItem(isDelete, item) {
      if (this.$refs.formNewUser.validate()) {
        this.currentValue = item ? item : this.currentValue;
        if (!!item || (!this.isEditing && this.editModal)) {
          fetch(`/api/user`, {
          method: 'POST',
          body: JSON.stringify({
            ...this.currentValue
          }),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(response => this.loadUsers())
        .catch(error => console.error(error));
        };

        if (this.isEditing && this.editModal) {
          fetch(`/api/user/${this.currentValue.id}`, {
          method: 'PUT',
          body: JSON.stringify({
            ...this.currentValue
          }),
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(response => this.loadUsers())
        .catch(error => console.error(error));
        };

        if (isDelete) {
          fetch(`/api/user/${this.currentValue.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
              if(response.status === 200 || response.status === 202) {
                this.saveSuccessAlert = true;
              } else {
                this.saveErrorAlert = true;
              }
              return response;
          })
        .then(response => this.loadUsers())
        .catch(error => console.error(error));
        };

          this.isEditing = false;
          this.editModal = false;
          this.currentValue = {};
      }
    },
    loadUsers() {
      fetch(`/api/Users`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.usersData = data;
          this.dataTableisLoaded = true;
        })
        .catch(error => console.error(error));
    },
    loadInitData() {
      fetch('/api/user/apps', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.appList = data;
          this.loadUsers();
        })
        .catch(error => console.error(error));
      fetch('/api/user/roles', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.rolList = data;
          this.loadUsers();
        })
        .catch(error => console.error(error));
    },
    isRowDeleted(item) {
      return item.isDeleted ? 'is-deleted' : '';
    }
  },
};
</script>
