<template>
  <div class="h-full">
    <div class="login h-full p-absolute w-full" style="background-image: url('bg-login.jpg')">
    </div>
    <Login/>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '../components/Login.vue';

export default {
  name: 'LoginView',
  components: {
    Login,
  },
};
</script>
