<template>
  <div class="search-container d-flex h-full flex-column">
    <v-main class="bg-ligth-blue pb-6 h-full">
      <v-container>
        <div class="p-absolute alert-container right-5 w-full">
          <div class="alert-item alert--success" v-if="saveSuccessAlert">
            <img src="check-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Cambios Guardados</h6>
              <p>Las ediciones han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="alert-item alert--error" v-if="saveErrorAlert">
            <img src="error-circle.png" alt="Guardar"/>
            <div class="alert-item--text ml-2">
              <h6 class="text-body-1 font-weight-bold">Error al guardar cambios.</h6>
              <p>Las ediciones no han sido guardadas con éxito.</p>
            </div>
            <v-btn min-width="10" class="close-alert" icon color="black">
              <v-icon color="#000000" class="" @click="saveErrorAlert = false">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-row class="pt-5">
          <v-col cols="12" md="3" sm="3">
            <v-card class="h-full">
              <v-list class="py-0">
                <v-list-item v-for="(item, index) in dataLabels":key="index" class="py-3 px-4 cursor-pointer aside-item"
                v-bind:class="{'active': index === currentList}" @click="changeCategory(index)">
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" md="9" sm="9" v-if="categoryData && dataTableisLoaded">
            <v-card class="h-full pa-3">
              <v-card-title>
                {{ dataLabels[currentList]}}
                <v-spacer></v-spacer>
                <v-text-field
                  v-if="dataLabels[currentList] != 'Fecha Sistema'"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Filtar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog
                v-if="dataLabels[currentList] != 'Fecha Sistema'"
                v-model="editModal"
                persistent
                max-width="450"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs"
                    v-on="on" class="label-input ml-10" color="#3C77AF">
                    <span class="white--text">Crear Nuevo</span>
                  </v-btn>
                </template>
                <v-card class="py-4">
                  <v-card-title class="text-h5">
                    {{ isEditing ? 'Editar': 'Crear' }}
                  </v-card-title>
                  <v-card-text>
                    <label class="label-input mb-2 d-inline-block">Nombre</label>
                    <v-text-field
                      class="solo-border"
                      v-model="currentValue.value"
                      placeholder=""
                      flat single-line solo>
                    </v-text-field>
                    <template v-if="dataLabels[currentList] === 'Feriados'">
                      <label class="label-input mb-2 d-inline-block">Fecha</label>
                      <v-menu v-model="dateFechaMenu" min-width="auto" offset-y
                              :close-on-content-click="false"
                              :nudge-right="40">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field class="solo-border" v-model="currentValue.date"
                                        placeholder="Fecha"
                                        flat single-line solo prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="currentValue.date"
                                      @input="dateFechaMenu = false"></v-date-picker>
                      </v-menu>
                    </template>
                    <template v-if="dataLabels[currentList] === 'Comisiones'">
                      <v-spacer></v-spacer>
                      <label class="label-input mb-2 d-inline-block">Integrantes</label>
                      <v-combobox class="solo-border no-wrap-select" multiple
                          placeholder="Integrantes"
                          flat
                          chips
                          v-model="currentValue.children"
                          :items="diputadosList"
                          item-value="key"
                          item-text="value"
                          return-object
                          solo></v-combobox>
                    </template>
                  </v-card-text>
                  <v-card-actions class="d-flex px-6">
                    <v-btn v-if="!currentValue.isDeleted && isEditing" class="label-input mr-auto" color="#E83656" @click="saveItem(true)">
                      <v-icon left class="white--text">
                        mdi-trash-can-outline
                      </v-icon>
                      <span class="white--text"> Eliminar</span>
                    </v-btn>
                    <v-btn v-if="currentValue.isDeleted" class="label-input mr-auto" color="#1867C0" @click="saveItem(false)">
                      <span class="white--text">Activar</span>
                    </v-btn>
                    <v-spacer v-if="!isEditing"></v-spacer>
                    <v-btn color="#1867C0" outlined class="label-input"
                          @click="editModal = false; isEditing = false">
                          Cancelar
                    </v-btn>
                    <v-btn class="label-input" color="#1867C0" dark
                        @click="saveItem()">
                        {{ isEditing ? 'Guardar' : 'Crear'}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </v-card-title>
              <div v-if="parentData && currentList != 13" class="px-4">
                <label v-if="dataLabels[currentList] === 'Diputados'" class="label-input mb-2 d-inline-block">Partido</label>
                <label v-if="dataLabels[currentList] === 'Comisiones'" class="label-input mb-2 d-inline-block">Grupo</label>
                <v-combobox class="solo-border" v-model="parentSelected" required
                  @change="onParentChange"
                  flat solo
                  :items="parentData"
                  item-value="key"
                  item-text="value"
                  return-object>
                </v-combobox>
                <v-data-table
                :headers="headers"
                :items="categoryDataFiltered"
                :item-class="isRowDeleted"
                :search="search"
                @click:row="editValue"
                  >
                  <template v-slot:item="row">
                      <tr v-bind:class="{'is-deleted': row.item.isDeleted }" @click="editValue(row.item)">
                        <td>{{row.item.value}}</td>
                        <td class="d-flex justify-end">
                          <v-btn dark icon class="mt-1" v-if="!row.item.isDeleted" color="#E83656" @click.stop="saveItem(true, row.item)">
                            <v-icon>
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                          <v-btn dark icon class="mt-1" v-if="row.item.isDeleted" color="#1867C0" @click.stop="saveItem(false, row.item)">
                            <v-icon>
                              mdi-checkbox-marked-circle-plus-outline
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                  </template>
                </v-data-table>
              </div>
              <v-data-table
              v-if="!parentData && dataLabels[currentList] != 'Fecha Sistema'"
              class="px-4"
                :headers="headers"
                :items="categoryData"
                :item-class="isRowDeleted"
                :search="search"
                @click:row="editValue"
              >
              <template v-slot:item="row">
                  <tr v-bind:class="{'is-deleted': row.item.isDeleted }" @click="editValue(row.item)">
                    <td v-if="dataLabels[currentList] === 'Feriados'">{{row.item.date}} - {{row.item.value}}</td>
                    <td v-if="dataLabels[currentList] !== 'Feriados'">{{row.item.value}}</td>
                    <td class="d-flex justify-end">
                      <v-btn dark icon class="mt-1" v-if="!row.item.isDeleted" color="#E83656" @click.stop="saveItem(true, row.item)">
                        <v-icon>
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                      <v-btn dark icon class="mt-1" v-if="row.item.isDeleted" color="#1867C0" @click.stop="saveItem(false, row.item)">
                        <v-icon>
                          mdi-checkbox-marked-circle-plus-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
              </template>
            </v-data-table>
              <div class="px-4" v-if="dataLabels[currentList] == 'Fecha Sistema'">
                <v-menu v-model="fechaSistemMenu" min-width="auto" offset-y
                          :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="solo-border" v-model="categoryData[0].value"
                                    placeholder="Fecha Presentación"
                                    required
                                    :rules="[v => !!v || 'Este valor requerido']"
                                    flat single-line solo prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="categoryData[0].value"
                                    @input="fechaSistemMenu = false"></v-date-picker>
                  </v-menu>
                  <v-btn class="label-input" color="#1867C0" dark
                        @click="saveItem(false, categoryData[0])">
                        Guardar
                    </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { format } from 'date-fns';
export default {
  name: 'SettingView',
  components: {
  },
  computed: {

  },
  created() {
    this.loadInitData();
  },
  data: () => ({
    dateFechaMenu: false,
    saveSuccessAlert: false,
    saveErrorAlert: false,
    fechaSistemMenu: false,
    diputadosList: [],
    editModal: false,
    isEditing: false,
    parentData: null,
    categoryDataFiltered: null,
    dataTableisLoaded: false,
    currentValue: {},
    search: '',
    headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'value',
        },
        {
          text: '',
          align: 'end',
          sortable: false
        }
      ],
    categoryData: null,
    currentList: '0',
    parentSelected: null,
    dataLabels: [
    ],
    initData: {},
  }),
  watch: {
    saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
  },
  methods: {
    getDate(dateString) {
      return format(new Date(dateString), 'dd-MM-yyyy');
    },
    createUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, 
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    editValue(item) {
      this.currentValue = null;
      this.editModal = true;
      this.isEditing = false;
      if (item) {
        this.isEditing = true;
        this.currentValue = { ...item };
      }
    },
    saveItem(isDelete, item) {
      this.currentValue = item ? item : this.currentValue;
      this.currentValue.isDeleted = isDelete;
      if (!this.currentValue.key) {
        this.currentValue.key = '00000000-0000-0000-0000-000000000000';
        if (this.parentSelected) {
          this.currentValue.parentKey = this.parentSelected.key;
        }
      };
      fetch(`/api/Settings/MetaData?type=${this.currentList}`, {
      method: 'POST',
      body: JSON.stringify({
        ...this.currentValue
      }),
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }).then(response => {
            if(response.status === 200 || response.status === 202) {
              this.saveSuccessAlert = true;
            } else {
              this.saveErrorAlert = true;
            }
            return response;
        })
      .then(response => this.loadCategoty())
      .catch(error => console.error(error));
        this.isEditing = false;
        this.editModal = false;
        this.currentValue = {};
    },
    changeCategory(cateogryId) {
      this.dataTableisLoaded = false;
      this.parentData = null;
      this.parentSelected = null;
      this.diputadosList = [];
      this.currentList = cateogryId;
      // Partidos
      if (this.dataLabels[cateogryId] === 'Diputados') {
        fetch(`/api/Settings/MetaData?type=${this.dataLabelsArray.indexOf('Partidos')}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.parentData = data;
          if(data.length > 0) {
            this.parentSelected = this.parentData[0];
          }
          this.loadCategoty();
        })
        .catch(error => console.error(error));
      } else if (this.dataLabels[cateogryId] === 'Comisiones') {
        fetch(`/api/Settings/MetaData?type=${this.dataLabelsArray.indexOf('Grupos de Comisión')}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.parentData = data;
          this.parentSelected = this.parentData[0];
          fetch(`/api/Settings/MetaData?type=${this.dataLabelsArray.indexOf('Diputados')}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          })
            .then(response => response.json())
            .then(data => {
              this.diputadosList = data;
              this.loadCategoty();
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
      } else {
        this.loadCategoty();
      }
    },
    loadCategoty() {
      fetch(`/api/Settings/MetaData?type=${this.currentList}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          if (this.dataLabels[this.currentList] === 'Fecha Sistema') {
            data[0].value = new Date(data[0].value).toISOString().slice(0, 10);
            this.categoryData = data;
          } else {
            this.categoryData = data;
          }

          if (this.parentData) {
            this.categoryDataFiltered = data.filter((item) => item.parentKey === this.parentSelected.key);
          }
          this.dataTableisLoaded = true;
        })
        .catch(error => console.error(error));
    },
    onParentChange() {
      this.categoryDataFiltered = this.categoryData.filter((item) => {
        return item.parentKey === this.parentSelected.key;
      });
    },
    loadInitData() {
      fetch('/api/Settings/MetaDataType', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.dataLabelsArray = Object.keys(data).map((clave) => data[clave].toString());
          this.dataLabels = data;
          this.loadCategoty();
        })
        .catch(error => console.error(error));
    },
    isRowDeleted(item) {
      return item.isDeleted ? 'is-deleted' : '';
    }
  },
};
</script>
