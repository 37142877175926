import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';
import SearchView from '../views/SearchView.vue';
import ProfileView from '../views/ProfileView.vue';
import UsersView from '../views/UsersView.vue';
import ProjectView from '../views/ProjectView.vue';
import SettingView from '../views/SettingView.vue';
import ReadOnlyProjectView from '../views/ReadOnlyProjectView.vue';
import ReadOnlyInputProjectView from '../views/ReadOnlyInputProjectView.vue';
import ProjectsEditorView from '../views/ProjectsEditor.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'DashboardView',
    component: DashboardView,
    redirect: {
      name: 'SearchView',
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'SearchView',
        path: '/search',
        component: SearchView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'ProfileView',
        path: '/profile',
        component: ProfileView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'UsersView',
        path: '/users',
        component: UsersView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/project/:id',
        name: 'ProjectView',
        component: ProjectView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/view-project-2/:id',
        name: 'ReadOnlyProjectView',
        component: ReadOnlyProjectView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/view-project/:id',
        name: 'ReadOnlyInputProjectView',
        component: ReadOnlyInputProjectView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/setting',
        name: 'SettingView',
        component: SettingView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/projectsEditor',
        name: 'ProjectsEditorView',
        component: ProjectsEditorView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/Login',
    name: 'LoginView',
    component: LoginView,
},
{
    path: '/ResetPassword',
    name: 'ResetView',
    component: LoginView,
},
{
    path: '/PasswordReset/:id',
    name: 'ChangeView',
    component: LoginView,
},
  {
    path: '/About',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  let isAuthenticated = false;

  fetch('/api/User/Authentication', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      isAuthenticated = false;

      if (response.status === 200) {
        isAuthenticated = true;
      }

      if (requiresAuth && !isAuthenticated) {
        next('/Login');
      } else {
        next();
      }
    })
    .catch((error) => {
      next('/Login');
      console.error(error);
    });
});
export default router;
