<template>
  <div class="login-container d-flex h-full pa-5">
    <div class="p-absolute alert-container right-5 w-full">
      <div class="alert-item alert--success" v-if="saveSuccessAlert">
        <img src="check-circle.png" alt="Guardar"/>
        <div class="alert-item--text ml-2">
          <h6 class="text-body-1 font-weight-bold">{{notificationTextHeader}}</h6>
        </div>
        <v-btn min-width="10" class="close-alert" icon color="black">
          <v-icon color="#000000" class="" @click="saveSuccessAlert = false">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="alert-item alert--error" v-if="saveErrorAlert">
        <img src="error-circle.png" alt="Guardar"/>
        <div class="alert-item--text ml-2">
          <h6 class="text-body-1 font-weight-bold">{{notificationTextHeader}}</h6>
          <p>{{notificationTextDescription}}</p>
        </div>
        <v-btn min-width="10" class="close-alert" icon color="black">
          <v-icon color="#000000" class="" @click="saveErrorAlert = false">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>
    <!-- <img src="bg-login.jpg" alt="Login BG"/> -->
    <v-card class="login-card w-full">
      <section class="d-flex flex-column">
        <img src="logo-con-texto-2021.svg" alt="Logo ABC" style="width: 280px;margin: 0 auto;"/>
        <div class="login-wrapper" v-if="showLogin">
          <h1 class="font-weight-bold text-h6 primary-dark-text mt-10">
            Iniciar Sesión
          </h1>
          <p class="text-body-2 mt-2">Inicie sesión para acceder a la plataforma.</p>
          <form class="mt-5" @submit.prevent="login">
              <label class="label-input mb-2 d-inline-block">
                Correo Electrónico
              </label>
              <v-text-field v-model="email" required class="solo-border"
              placeholder="Correo Electrónico"
              flat single-line solo>
              </v-text-field>
              <label class="label-input mb-2 d-inline-block">Contraseña</label>
              <v-text-field type="password" v-model="password" required class="solo-border" placeholder="Contraseña" flat single-line solo></v-text-field>
              <v-btn type="submit" class="label-input w-full mt-8" dark color="#1867C0"
                :loading="isSelecting">
                <span class="white--text font-weight-bold">Iniciar Sesión</span>
              </v-btn>
              <v-btn type="button" class="label-input w-full mt-4" text color="#1867C0"
                @click="showReset = true; showLogin = false">
                <span class="font-weight-bold">Olvidó su contraseña?</span>
              </v-btn>
          </form>
        </div>
        <div class="reset-wrapper" v-if="showReset">
          <h1 class="font-weight-bold text-h6 primary-dark-text mt-10">
            Restablecer Contraseña
          </h1>
          <p class="text-body-2 mt-2">Si has olvidado tu contraseña, no hay problema. Sólo necesitas tu dirección de correo electrónico para comenzar el proceso de restablecimiento.</p>
          <form class="mt-5" @submit.prevent="resetPassword">
              <label class="label-input mb-2 d-inline-block">Correo Electrónico</label>
              <v-text-field v-model="emailReset" required class="solo-border" placeholder="Correo Electrónico" flat single-line solo></v-text-field>
              <v-row>
                <v-col cols="6" class="pr-2">
                  <v-btn type="button" class="label-input w-full mt-8" outlined color="#1867C0" @click="showReset=false;showLogin=true">
                    <span class="font-weight-bold">Volver Atras</span>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-btn type="submit" class="label-input w-full mt-8" dark color="#1867C0"
                  :loading="isSelecting">
                    <span class="white--text font-weight-bold">Restablecer</span>
                  </v-btn>
                </v-col>
              </v-row>
            </form>
        </div>
        <div class="change-password-wrapper" v-if="showChange">
          <h1 class="font-weight-bold text-h6 primary-dark-text mt-10">
            Cambiar Contraseña
          </h1>
          <p class="text-body-2 mt-2">Cambia tu contraseña en unos simples pasos. Ingresa tu contraseña nueva para actualizar tus credenciales de acceso.</p>
          <form class="mt-5" @submit.prevent="changePassword">
              <label class="label-input mb-2 d-inline-block">Contraseña</label>
              <v-text-field
                  v-model="passwordReset" flat single-line solo
                  class="solo-border"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rulesPassword.required, rulesPassword.min]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Contraseña nueva"
                  hint="minimo 8 caracteres"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              <v-btn type="submit" class="label-input w-full mt-8" dark color="#1867C0"
                :loading="isSelecting">
                <span class="white--text font-weight-bold">Cambiar Contraseña</span>
              </v-btn>
            </form>
        </div>
        <div class="multiapp-wrapper" v-if="showMultiApp">
          <h1 class="font-weight-bold text-h6 primary-dark-text mt-10">
            Seleccione Aplicación
          </h1>
          <p class="text-body-2 mt-2">Selecciona la aplicación a la que deseas acceder.</p>
          <div class="app-list">
            <a  v-for="(item, index) in appList" :key="index" class="app-item" color="white" :href="item.url">
              <v-icon color="#000000" class="">
                {{item.icono}}
              </v-icon>
              <p>{{item.name}}</p>
            </a>
          </div>
        </div>
      </section>
    </v-card>
  </div>
</template>

<script>
export default {
  created() {
    this.initScreen();
  },
  data() {
    return {
      email: '',
      emailReset: '',
      password: '',
      passwordReset: '',
      isSelecting: false,
      showMultiApp: false,
      showLogin: false,
      showReset: false,
      showChange: false,
      showPassword: false,
      saveSuccessAlert: false,
      saveErrorAlert: false,
      notificationTextHeader: '',
      notificationTextDescription: '',
      notificationText: 'restablecer contraseña',
      rulesPassword: {
        required: value => !!value || 'Required.',
        min: v => v && v.length >= 8 || 'Min 8 characters',
      },
      appList:[]
    }
  },
  watch: {
    saveSuccessAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveSuccessAlert=false},3000)
        }
      },
      saveErrorAlert(new_val){
        if(new_val){
          setTimeout(()=>{this.saveErrorAlert=false},6000)
        }
      }
  },
  methods: {
    initScreen() {
      if(this.$route.name === 'LoginView'){
        this.showLogin = true;
      }
      if(this.$route.name === 'ResetView'){
        this.showReset = true;
      }
      if(this.$route.name === 'ChangeView'){
        this.showChange = true;
        this.notificationText = 'cambiar contraseña.'
      }
    },
    login() {
      var self = this;
      this.isSelecting = true;
      fetch('/api/Public/Login', {
        method: 'POST',
        body: JSON.stringify({
          UserEmail: self.email,
          Password: self.password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          this.isSelecting = false;
          // Store the JWT token in local storage
          this.appList = data.apps;
          localStorage.setItem('searchSelected', null);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userFullName', data.userFullName);

          // role should came in login result
          fetch(`/api/user`, {
            method: 'get',
            headers: {
              'Authorization': `Bearer ${data.token}`,
              'Content-Type': 'application/json',
            },
          })
          .then(response => response.json())
          .then(data => {
            localStorage.setItem('userRole', data.roleId);
            if(data.apps.length > 1){
              this.showLogin = false;
              this.showMultiApp = true;
            } else {
              window.location = data.apps[0].url;
              // this.$router.push('/');
            }
          })
          .catch(error =>{
            this.notificationTextHeader = 'Error Inicio de sesión';
            this.notificationTextDescription = 'El correo electronico o contraseña son incorrectos.';
            this.saveErrorAlert = true;
          });
          // Redirect to the home page
         
        })
        .catch(error => console.error(error));
    },
    resetPassword() {
      this.isSelecting = true;
      fetch('/api/Public/RequestResetPassword', {
        method: 'POST',
        body: JSON.stringify({
          userEmail: this.emailReset
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(data => {
          this.isSelecting = false;
            this.showLogin = true;
            this.showReset = false;
            this.emailReset = '';
            this.notificationTextHeader = "Se ha enviado un correo para restablecer su contraseña.";
            this.notificationTextDescription = '';
            this.saveSuccessAlert = true;
        })
        .catch(error =>{
          this.notificationTextHeader = "Error al restablecer la contraseña, vuelve a intentarlo.";
          this.notificationTextDescription = '';
          this.saveErrorAlert = true;
        });
    },
    changePassword() {
        this.isSelecting = true;
        fetch('/api/Public/RequestResetPassword', {
          method: 'POST',
          body: JSON.stringify({
            resetPasswordToken: this.$route.params.id,
            password: this.passwordReset
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(data => {
            this.isSelecting = false;
            this.notificationTextHeader = "Su contraseña ha sido cambiada con éxito";
            this.notificationTextDescription = '';
              this.showLogin = true;
              this.showChange = false;
              this.passwordReset = '';
              this.saveSuccessAlert = true;
          })
          .catch(error =>{
            this.notificationTextHeader = "Error al cambiar la contraseña, vuelve a intentarlo.";
            this.notificationTextDescription = '';
            this.saveErrorAlert = true;
          });
      //
    }
  }
}
</script>
